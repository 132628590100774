//@flow
import type { TBreadCrumb, TSingleCrumb } from '../reducers';

export const OPEN_SIDE_MENU = 'OPEN_SIDE_MENU';
export const CLOSE_SIDE_MENU = 'CLOSE_SIDE_MENU';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const SET_CRUMB_PATH = 'SET_CRUMB_PATH';
export const PUSH_CRUMB_PATH = 'PUSH_CRUMB_PATH';
export const POP_CRUMB_PATH = 'POP_CRUMB_PATH';
export const SET_CRUMB_SEARCH = 'SET_CRUMB_SEARCH';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export function showSnackbar(message: string) {
    return {
        type: SHOW_SNACKBAR,
        payload: { message }
    };
}

export function hideSnackbar() {
    return {
        type: HIDE_SNACKBAR,
        payload: {}
    };
}

export function openSideMenu() {
    return {
        type: OPEN_SIDE_MENU,
        payload: {}
    };
}

export function closeSideMenu() {
    return {
        type: CLOSE_SIDE_MENU,
        payload: {}
    };
}

export function toggleSideMenu() {
    return {
        type: TOGGLE_SIDE_MENU,
        payload: {}
    };
}

export function setCrumbPath(breadCrumb: TBreadCrumb) {
    return {
        type: SET_CRUMB_PATH,
        payload: breadCrumb
    };
}

export function pushCrumbPath(crumb: TSingleCrumb) {
    return {
        type: PUSH_CRUMB_PATH,
        payload: crumb
    };
}

export function popCrumbPath(crumb) {
    return {
        type: POP_CRUMB_PATH,
        payload: crumb
    };
}


export function setCrumbSearch(query) {
    return {
        type: SET_CRUMB_SEARCH,
        payload: { query }
    };
}

export function closeDialog(name) {
    return {
        type: CLOSE_DIALOG,
        payload: {
            name: name
        }
    };
}

export function openDialog(name, data = {}) {
    return {
        type: OPEN_DIALOG,
        payload: {
            name,
            data
        }
    };
}
