// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import ToolboxOverview from '../ToolboxOverview';
import ToolboxAssignedOverview from '../ToolboxAssignedOverview';
import ToolboxEdit from '../ToolboxEdit';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ToolboxScheduledOverview from '../ToolboxScheduledOverview/ToolboxScheduledOverview';
import { isUndefinedOrNull } from '../../lib/utils';
import {getToolboxByIdV2, setCrumbPath} from '../../actions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './Toolbox.module.scss';
import colors from "../../lib/themes/theme.scss";

type Props = {
    toolboxAssigns: any,
    editToolbox: Function,
    getToolboxById: () => mixed,
    match: any,
};

type State = {
    tab: number,
};

const mapStateToProps = (store, props) => {
    let toolbox = store.entities.toolbox.byId[props.match.params.id];

    if (isUndefinedOrNull(toolbox) || isUndefinedOrNull(toolbox.toolbox)) {
        if (!isUndefinedOrNull(toolbox)) {
            toolbox = {
                ...toolbox,
                ...store.entities.toolbox.byId[props.match.params.id],
            };
        }
    }

    return {
        toolbox: toolbox,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editToolbox: (id) => {
            dispatch(push('/toolboxen/' + id));
        },
        getToolboxById: (toolboxId) => {
            dispatch(getToolboxByIdV2(toolboxId));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Toolboxen' }));
        },

    };
};

class Toolbox extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    componentDidMount() {
        this.props.setCrumbPath();

        const tabs = ['#overzicht', '#ingepland', '#uitgestuurd'];

        const { match, toolbox, getToolboxById } = this.props;

        if (isUndefinedOrNull(toolbox) && !isUndefinedOrNull(match.params.id) ) {
            console.log("Toolbox", "Retrieving toolbox " + match.params.id)
            getToolboxById(match.params.id);
        }


        if (this.props.location.hash) {
            let index = tabs.indexOf(this.props.location.hash.split("?")[0]);
            if (index === -1) index = 0;

            this.setState({
                tab: index,
            });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        const { match, toolbox, getToolboxById } = this.props;
        if (
            prevProps.match.params.id !== match.params.id &&
            !isUndefinedOrNull(match.params.id) &&
            (isUndefinedOrNull(toolbox) ||
                isUndefinedOrNull(toolbox.byId) ||
                isUndefinedOrNull(toolbox.byId[match.params.id]))
        ) {
            getToolboxById(match.params.id);
        }
    }

    render() {
        const paramId = this.props.match.params.id;
        const showDetailDialog = paramId !== undefined;

        const { toolbox, t } = this.props;
        const { tab } = this.state;

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{ '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab label={t('Toolboxen')} href="#overzicht" sx={{ color: colors.mainTabsColor }} />
                    <Tab label={t('Scheduled')} href="#ingepland" sx={{ color: colors.mainTabsColor }}/>
                    <Tab label={t('Sent out')} href="#uitgestuurd" sx={{ color: colors.mainTabsColor }}/>
                </Tabs>

                {tab === 0 && <ToolboxOverview />}
                {tab === 1 && <ToolboxScheduledOverview />}
                {tab === 2 && <ToolboxAssignedOverview />}
                <ToolboxEdit open={showDetailDialog} toolbox={toolbox} />
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Toolbox);
