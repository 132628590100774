// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';


import _ from 'lodash';
import { setCrumbPath } from '../../actions';
import {
    fetchAbonneesCUMELA,
    fetchHistory,
} from '../../actions/subscriptionActions';
import AbonneeInformation from '../Subscriptions/AbonneeInformation';
import { compose } from 'recompose';
import i18n from "i18next";
import moment from "../../lib/moment";


type Props = {
    match: any,
    abonnee?: any,
    fetchAbonneesCUMELA: () => mixed,
    setCrumbPath: (abonneeCompanyName: string) => void,
    fetchHistory: (id: Number) => mixed,
    history: Array<any>,
};

const mapStateToProps = (store, props) => {
    const abonneesCUMELA = store.subscription.abonneesCUMELA;
    const abonneeId = props.match.params.abonneeId;

    const history = store.subscription.history;

    let abonneesById = [];
    if (abonneesCUMELA) {
        abonneesCUMELA.forEach((abonee) => {
            abonneesById[abonee.id] = abonee;
        });
    }

    let abonnee = abonneesById[abonneeId];

    return {
        abonnee: abonnee,
        abonneesCUMELA: abonneesCUMELA,
        abonneesById: abonneesById,
        history: history,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAbonneesCUMELA: () => {
            dispatch(fetchAbonneesCUMELA());
        },
        setCrumbPath: (abonneeCompanyName: string) => {
            dispatch(
                setCrumbPath({
                    title: 'Abonnees',
                    crumbs: [
                        { name: abonneeCompanyName, link: '/abonneesCUMELA' },
                    ],
                })
            );
        },
        fetchHistory: (id: Number) => {
            dispatch(fetchHistory(id));
        },
    };
};

class AbonneeDetailViewCUMELA extends React.Component<Props, State> {
    props: Props;

    state: State = {
        searchtext: '',
        actions: [],
        columns: [
            // { id: 'subscriptionId', numeric:true, size: '10%', label: 'Abonnee id' },
            {
                id: 'maxEmployees',
                numeric: true,
                size: '17%',
                label: 'Max medewerkers',
            },
            {
                id: 'activatedOn',
                numeric: false,
                size: '13%',
                label: 'Veranderd',
            },
        ],
    };

    componentDidMount() {
        moment.locale(i18n.t('LOCALE'));

        this.props.fetchAbonneesCUMELA();

        if (this.props.abonnee) {
            this.props.setCrumbPath(this.props.abonnee.companyName);
            this.props.fetchHistory(this.props.abonnee.id);
        }

        this.setState({
            columns: [
                // { id: 'subscriptionId', numeric:true, size: '10%', label: 'Abonnee id' },
                {
                    id: 'maxEmployees',
                    numeric: true,
                    size: '17%',
                    label: i18n.t('Max employees'),
                },
                {
                    id: 'activatedOn',
                    numeric: false,
                    size: '13%',
                    label: i18n.t('Changed'),
                },
            ]
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.abonnee && _.get(this, 'props.abonnee.id', null) !== _.get(prevProps, 'abonnee.id', null)) {
            this.props.setCrumbPath(this.props.abonnee.companyName);
        }
    }

    render() {
        const { abonnee } = this.props;
        const columns = this.state.columns;
        const history = this.props.history;

        return (
            <div>
                <AbonneeInformation
                    abonnee={abonnee}
                    columns={columns}
                    history={history}
                    cumela={false}
                />
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
)(AbonneeDetailViewCUMELA);
