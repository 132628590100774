// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import { connect } from 'react-redux';

import { Card, CardContent, Button } from '@mui/material';
import Loader from '../Loader';
import FileUploadIcon from '@mui/icons-material/CloudUpload';
import { processForm, uploadPhoto } from '../../actions';
import { getForm } from '../../selectors';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import classes from './SimpleImageInput.module.scss';

type Props = {};
type State = {};


const mapStateToProps = (store, props) => {
    return getForm(props.id)(store);
};

const mapDispatchToProps = (dispatch, props) => ({
    onSelectFile: (file) => dispatch(processForm(props.id, uploadPhoto(file))),
});

class SimpleImageInput extends React.Component {
    props: Props;
    state: State = {
        loadingImage: true,
    };

    componentDidMount() {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.innerHTML =
            "document.getElementById('mui-b" +
            this.props.id +
            "').onclick = function() { document.getElementById('inputfile-" +
            this.props.id +
            "').click();};";
        script.async = true;
        document.body.appendChild(script);
    }

    handleInputChange = (event) => {
        if (event.target.files[0]) {
            if (event.target.files[0].size > 3000000) {
                alert(i18n.t('The selected file exceeds tha maximum of 3Mb'));
            } else {
                this.setState({ loadingImage: true });
                this.props.onSelectFile(event.target.files[0]);
            }
        }
    };

    handleImageLoad = (event) => {
        this.setState({ loadingImage: false });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.url !== prevProps.url) {
            this.setState({ loadingImage: true });
        }

        if (this.props.result !== prevProps.result) {
            this.props.onChange({ ...this.props.result });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        //const nextLoading = (nextProps.url && nextState.loadingImage) || nextProps.processing;
        //const currentLoading = (this.props.url && this.state.loadingImage) || this.props.processing;
        //const willUpdate = (nextLoading && !currentLoading) || (nextProps.url !== undefined && nextProps.url !== null && nextLoading !== currentLoading);
        return true;
    }

    render() {
        const {
            url,
            id,
            processing,
            disabled = false,
            t,
        } = this.props;
        const { loadingImage } = this.state;

        return (
            <Card className={classes.root}>
                <Loader loading={(url && loadingImage) || processing} size={50}>
                    <CardContent className={classes.wrapper}>
                        <Button
                            className={classes.button}
                            id={'mui-b' + id}
                            disabled={disabled}
                        >
                            <label className={classes.label}>
                                {url && (
                                    <img
                                        alt={t('Upload icon')}
                                        onLoad={this.handleImageLoad}
                                        className={classnames({
                                            [classes.preview]: true,
                                            [classes.previewLoading]: loadingImage,
                                        })}
                                        src={url}
                                    />
                                )}
                                {!url && (
                                    <div className={classes.labelWrapper}>
                                        <FileUploadIcon className={classes.uploadIcon} />
                                        <div>{t('Upload photo')}</div>
                                    </div>
                                )}
                            </label>
                        </Button>
                        {!disabled && (
                            <input
                                accept="image/*"
                                className={classes.input}
                                id={'inputfile-' + id}
                                onChange={this.handleInputChange}
                                type="file"
                            />
                        )}
                    </CardContent>
                </Loader>
            </Card>
        );
    }
}

export default compose(
    
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(SimpleImageInput);
