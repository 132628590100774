//@flow
import { combineReducers } from 'redux';
import formsReducer from './formsReducer';
import {
    SET_CRUMB_PATH,
    PUSH_CRUMB_PATH,
    POP_CRUMB_PATH,
    OPEN_DIALOG,
    CLOSE_DIALOG,
    OPEN_SIDE_MENU,
    CLOSE_SIDE_MENU,
    TOGGLE_SIDE_MENU,
    SHOW_SNACKBAR,
    HIDE_SNACKBAR
} from '../actions';
import { List } from 'immutable';

const initialState = {
    sideMenu: {
        isOpen: false
    },
    dialogs: {
        DialogIE: {
            open:false
        },
        toolboxCreate: {
            open: false
        },
        toolboxDeploy: {
            open: false,
            data: {}
        },
        inspectionCreate: {
            open: false
        },
        inspectionDeploy: {
            open: false,
            data: {}
        },
        pasteListItems: {
            open: false,
            data: {}
        },
        error: {
            open: false
        }
    },
    breadCrumb: {
        title: '',
        crumbs: []
    },
    snackbar: {
        open: false,
        message: ''
    }
};

export type TBreadCrumb = {
    title: string,
    crumbs: List<{
        link: string,
        name: string
    }>
};

export type TSingleCrumb = {
    crumbs:{
        link: string,
        name: string
    }
};

function uiSnackbarReducer(state = initialState.snackbar, action = {}) {
    switch (action.type) {
        case SHOW_SNACKBAR: {
            const { message } = action.payload;
            return { ...state, open: true, message: message };
        }
        case HIDE_SNACKBAR: {
            return { ...state, open: false };
        }
        default:
            return state;
    }
}

function uiSideMenuReducer(state = initialState.sideMenu, action = {}) {
    switch (action.type) {
        case OPEN_SIDE_MENU:
            return { ...state, isOpen: true };
        case CLOSE_SIDE_MENU:
            return { ...state, isOpen: false };
        case TOGGLE_SIDE_MENU:
            return { ...state, isOpen: !state.isOpen };
        default:
            return state;
    }
}

function uiDialogsReducer(state = initialState.dialogs, action = {}) {
    switch (action.type) {
        case OPEN_DIALOG: {
            // window.$crisp.push(['do', 'chat:hide']);
            const { name, data } = action.payload;
            const newDialogsState = {};
            newDialogsState[name] = { ...initialState.dialogs[name], open: true, data: data };
            return { ...state, ...newDialogsState };
        }
        case CLOSE_DIALOG: {
            // window.$crisp.push(['do', 'chat:show']);
            const { name } = action.payload;
            const newDialogsState = {};
            newDialogsState[name] = { ...initialState.dialogs[name], open: false };
            return { ...state, ...newDialogsState };
        }
        default:
            return state;
    }
}

function crumbsReducer(state = initialState.breadCrumb, action = {}) {
    switch (action.type) {
        case SET_CRUMB_PATH:
            return action.payload;
        case PUSH_CRUMB_PATH:
            if (state.title) {
                // Append crumbs
                let crumbs = []
                if (state.crumbs) {
                    crumbs = state.crumbs
                    crumbs.push(action.payload.crumb);
                } else {
                    crumbs.push(action.payload.crumb)
                }
                action.payload.crumbs = crumbs;
                action.payload.title = state.title;
            } else {
                if (action.payload.crumb) {
                    action.payload.crumbs = []
                    action.payload.crumbs.push(action.payload.crumb)
                }
            }
            return action.payload;

        case POP_CRUMB_PATH:
            // Remove last crumb
            let newState = {
                title: '',
                crumbs: []
            };
            newState.title = state.title ? state.title : '';
            if (state.crumbs) {
                newState.crumbs = state.crumbs;
                newState.crumbs.pop();
            }
            return newState;

        default:
            return state;
    }
}

const uiReducer = combineReducers({
    sideMenu: uiSideMenuReducer,
    dialogs: uiDialogsReducer,
    forms: formsReducer,
    breadCrumb: crumbsReducer,
    snackbar: uiSnackbarReducer
});

export default uiReducer;
