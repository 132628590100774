// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {Fab} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { withRouter } from 'react-router';
import { inProgress, getPermissions } from '../../selectors';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {
    openDialog,
    setCrumbPath,
    pushCrumbPath,
    fetchUsergroups,
    deleteUsergroup,
} from '../../actions';
import ConfirmDialog from '../ConfirmDialog';
import AppModule from '../AppModule';
import EnhancedTable from '../EnhancedTable';
import DialogUsergroupCreate from '../DialogUsergroupCreate';
import DialogUsergroupEdit from '../DialogUsergroupEdit';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import classes from './Usergroup.module.scss';


type Props = {
    loading: boolean,
    permissions: any,
    setCrumbPath: () => void,
    deleteUsergoup: (usergroup: string) => void,
    deleteGroup: void,
    createUsergroup: Function,
    editUsergroup: Function,
};

type State = {
    columns: Array<any>,
    showConfirmDialog: boolean,
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean,
    }>,
    groupToDelete: String,
    editGroup: any,
};

const mapStateToProps = (store) => {
    let usergroups = store.entities.usergroups;

    return {
        allUsergroups: usergroups,
        usergroups: usergroups.allIds.map((id) => usergroups.byId[id]),
        loading: !inProgress(store),
        permissions: getPermissions(store),
        openTheDialog: store.ui.dialogs.usergroupCreate
            ? store.ui.dialogs.usergroupCreate.open
            : false,
        openEditDialog: store.ui.dialogs.usergroupEdit
            ? store.ui.dialogs.usergroupEdit.open
            : false,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchUsergroups: () => {
            dispatch(fetchUsergroups());
        },
        deleteUsergoup: (usergroup, t) => {
            dispatch(deleteUsergroup(usergroup, t));
        },
        createUsergroup: () => {
            dispatch(openDialog('usergroupCreate'));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: i18n.t('Medewerkers') }));
        },
        goToDetailView: (usergroupId, page, rowsPerPage) => {
            dispatch(push('/groep/details/' + usergroupId + '/' + page + '/' + rowsPerPage));
        },
        editUsergroup: () => {
            dispatch(openDialog('usergroupEdit'));
        },
    };
};

class Usergroup extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        actions: [],
        columns: [],
    };

    setPage = (page) => {
        console.log("INFO: Usergroup page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: Usergroup rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }

    mapUsergroupToRow = (usergroup) => ({
        ...usergroup,
        employees:
            usergroup.users && usergroup.users.length
                ? usergroup.users.length
                : 0,
    });

    handleAction = (event, action) => {
        if (action.id === 'delete') {
            const group = this.props.allUsergroups.byId[action.rowId];
            if (group) {
                this.setState({ groupToDelete: group });
                this.showConfirmDialog();
            }
        }
        if (action.id === 'modify') {
            this.setState({
                editGroup: action.rowId,
            });
            this.props.editUsergroup();
        }
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    handleAddButtonClick = (event) => {
        this.props.createUsergroup();
    };

    componentDidMount() {
        console.log("INFO: Usergroup: componentDidMount()");
        // If url params rows and page go to right page
        const searchString = '?' + this.props.location.hash.split("?")[1]
        console.log("INFO: Custom searchString: " + searchString);

        if (searchString.includes("page")) {
            const jumpToPage = new URLSearchParams(searchString).get("page");
            console.log("INFO: page from SearchParams: " + jumpToPage);
            this.setState({ startPage: Number(jumpToPage)});
        }
        if (searchString.includes("rows")) {
            const startRowsPerPage = new URLSearchParams(searchString).get("rows");
            console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
            this.setState({ startRowsPerPage: Number(startRowsPerPage)});

        }

        this.props.setCrumbPath();
        this.setState({
            columns: [
                { id: 'name', label: 'Name', size: '40%' },
                { id: 'employees', label: '#Employees', size: '10%' },
            ],
        });

        let actions = [];

        actions.push(
            {
                id: 'modify',
                label: 'Change',
                isVisible: true,
            },
            {
                id: 'delete',
                label: 'Delete',
                isVisible: true,
            }
        );

        this.setState({
            actions: actions,
            columns: [
                { id: 'name', label: 'Name', size: '40%' },
                { id: 'employees', label: '#Employees', size: '10%' },
            ],
        });
    }

    onRowClick = (row) => {
        if (row.id) {
            // Set crumb for the Groepen tab
            this.props.pushCrumbPath({crumb: { name: i18n.t('Groups'), link: `/medewerkers#groepen?page=${this.state.page}&rows=${this.state.rowsPerPage}`}});

            // Set crumb for this detail view
            // dispatch(push('/groep/details/' + usergroupId + '/' + page + '/' + rowsPerPage));
            // this.props.pushCrumbPath({crumb: { name: row.name , link: `/groep/details/${row.id}`}});
            this.props.goToDetailView(row.id, this.state.page, this.state.rowsPerPage);
        }
    };

    render() {
        const { loading, usergroups, openEditDialog, t } = this.props;
        const { actions, columns } = this.state;
        const tableActionHeader = (
            <TableActionHeader
                title={
                    (usergroups ? usergroups.length : 0) +
                    ' ' +
                    t('Group') +
                    (usergroups.length === 1 ? '' : t('and'))
                }
            />
        );

        if (!usergroups) {
            return <div />;
        }

        return (
            <AppModule loading={loading} hasTabs prepend={tableActionHeader}>
                <EnhancedTable
                    hover
                    defaultOrderBy={'title'}
                    defaultOrder="desc"
                    columns={columns}
                    onClick={this.onRowClick}
                    rows={usergroups}
                    formatter={this.mapUsergroupToRow}
                    actions={actions}
                    onAction={this.handleAction}
                    emptyState={t(
                        'No groups have been added yet, add a group by clicking the plus sign at the top right.'
                    )}

                    onPageChange={this.setPage}
                    onRowsPerPageChange={this.setRowsPerPage}
                    startPage={this.state.startPage}
                    startRowsPerPage={this.state.startRowsPerPage}

                />

                <div
                    className={classnames({
                        [classes.button]: true,
                        'mui-fixed': true,
                    })}
                >
                    <Fab
                         
                        onClick={this.handleAddButtonClick}
                    >
                        <AddIcon />
                    </Fab>
                </div>

                <DialogUsergroupCreate open={this.props.openTheDialog} />
                <DialogUsergroupEdit
                    open={openEditDialog}
                    editGroup={this.state.editGroup}
                />
                <ConfirmDialog
                    open={this.state.showConfirmDialog}
                    handleClose={this.hideConfirmDialog}
                    deletegroup={this.state.groupToDelete}
                    deleteGroupFunction={this.props.deleteUsergoup}
                />
            </AppModule>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(Usergroup))
);

