// @flow

import { connect } from 'react-redux';
import React from 'react';
import { setCrumbPath } from '../../../actions';
import ToolboxDetailView from '../../Toolbox/ToolboxDetailView';

type Props = {};

const mapStateToProps = (state, props) => {
    const toolboxId = props.match.params.toolboxId;
    const toolboxAssign = state.entities.toolboxAssigns.byId[toolboxId];
    return { toolboxAssign, toolbox: toolboxAssign ? toolboxAssign.toolbox : null };
};

const mapDispatchToProps = dispatch => {
    return {
        setCrumbPath: toolboxAssign => {
            dispatch(
                setCrumbPath({
                    title: 'Rapportages',
                    crumbs: [
                        { name: 'Toolbox: ' + toolboxAssign.toolbox.title, link: '/rapportages#toolboxen' },
                        { name: 'Versie: ' + toolboxAssign.toolbox.version, link: '/rapportages/toolbox/' + toolboxAssign.toolbox.id}
                    ]
                })
            );
        }
    };
};

class StatisticsToolboxDetailView extends React.Component<Props, void> {
    props: Props;

    render() {
        return <ToolboxDetailView {...this.props} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsToolboxDetailView);
