// @flow

import { connect } from 'react-redux';
import React from 'react';
import InspectionDetailView from '../../Inspection/InspectionDetailView';
import ToolboxResultsViewStats from "../../Toolbox/ToolboxResultsView/ToolboxResultsViewStats";

type Props = {};

let employerId;
let employee;

const mapStateToProps = (state, props) => {
    const inspectionId = props.match.params.inspectionId;
    employerId = props.match.params.employerId;
    employee = state.entities.employees.byId[employerId];
    const inspectionAssign =
        state.entities.inspectionAssigns.byId[inspectionId];
    return {
        inspectionAssign,
        inspection: inspectionAssign ? inspectionAssign.inspection : null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

class StatisticsEmployeeDetailView extends React.Component<Props, void> {
    props: Props;

    render() {
        if (this.props.inspection === null) {
            return <ToolboxResultsViewStats {...this.props} />;
        } else {
            return <InspectionDetailView {...this.props} />;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatisticsEmployeeDetailView);
