// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { List } from 'immutable';
import type { TEmployee } from '../../reducers/employeesReducer';
import { bindActionCreators } from 'redux';

import EmployeeEdit from './EmployeeEdit';
import EmployeeOverview from './EmployeeOverview';
import { fetchEmployees, setCrumbPath, editEmployee } from '../../actions';
import ExternOverview from './EmployeeOverview/ExternOverview';

type Props = {
    match: any,
    employees: List<TEmployee>,
    employee: TEmployee,
    editEmployee: (employee: TEmployee) => void,
    setEditEmployee: (employee: TEmployee) => void,
    display: string,
    vv_functions: boolean,
};

type State = {
    detailDialogOpen: boolean,
};

const mapStateToProps = (store, props) => {
    const loading = store.entities.employees.isFetching;
    const employees = store.entities.employees;
    const employee = store.drafts.employees.editemployee;

    return {
        loading: loading,
        employees: employees.allIds.map((id) => employees.byId[id]),
        employeesById: employees.byId,
        employee: employee,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchEmployees: () => {
            dispatch(fetchEmployees(ownProps.display));
        },
        editEmployee: (id) => {
            dispatch(push('/medewerkers/' + id));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Medewerkers' }));
        },
        setEditEmployee: bindActionCreators(editEmployee, dispatch),
    };
};

class Employee extends React.Component<Props, State> {
    props: Props;
    state: State = {
        detailDialogOpen: false,
    };

    handleRequestClose = (event) => {
        this.setState({ detailDialogOpen: false });
    };

    componentDidMount() {
        this.props.fetchEmployees();
        const paramId = this.props.params.id
            ? parseInt(this.props.params.id, 10)
            : undefined;

        if (paramId == undefined && this.props.employee) {
            // Clear Dialog
            if (this.props.employeesById) {
                this.props.setEditEmployee(this.props.employeesById[paramId]);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.employees !== this.props.employees) {
            this.processProps(this.props)
        }
    }

    processProps = (props: Props) => {
        const paramId = props.params.id
            ? parseInt(props.params.id, 10)
            : undefined;

        // TODO: This processProps nightmare needs to be refactored. With a action state: Add / Edit
        if (props.employee && props.employee.id && props.params && props.params.id === undefined) {
            // Clear dialog
            if (props.employeesById) {
                props.setEditEmployee(null);
            }
        }

        if (!props.employee && paramId) {
            if (props.employeesById) {
                props.setEditEmployee(props.employeesById[paramId]);
            }
        }

        if (paramId && props.employee && (props.employee.id != paramId)) {
            if (props.employeesById) {
                props.setEditEmployee(props.employeesById[paramId]);
            }
        }


    };

    render() {
        const showDetailDialog = this.props.params.id !== undefined;
        const { employees, employee, vv_functions } = this.props;

        return (
            <div>
                {this.props.display === 'employee' ? (
                    <EmployeeOverview
                        employees={employees}
                        startEditEmployee={this.props.editEmployee}
                        display={this.props.display}
                    />
                ) : (
                    <ExternOverview
                        employees={employees}
                        startEditEmployee={this.props.editEmployee}
                        display={this.props.display}
                        vv_functions={vv_functions}
                    />
                )}
                <EmployeeEdit
                    open={showDetailDialog}
                    editemployee={employee}
                    display={this.props.display}
                />
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Employee);
