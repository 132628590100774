// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {
    AppBar,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import Select from 'react-select';
import CloseIcon from '@mui/icons-material/Close';


import {setCrumbPath, showSnackbar} from '../../actions';
import classes from './DialogEditTask.module.scss';
import colors from '../../lib/themes/theme.scss'
import {apiAddUpdateTask, apiDeleteTaskById } from "../../lib/api";
import i18n from "i18next";
import Slide from "@mui/material/Slide";
import moment from "moment";


type Props = {
};

type State = {
    done: boolean,
};

const mapStateToProps = (store, props) => {

    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Tasks/' }));
        }
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const selectStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    control: provided => ({ ...provided, borderColor: "#333", "&:focus": { borderColor: colors.primaryColor, boxShadow: "0 0 0 1px #333" }, "&:hover": { borderColor: colors.primaryColor}}),
    option: (provided, state) => {
        return {
            ...provided,
            color: "black",
            backgroundColor: state.isSelected ? colors.primaryColor : "white",
            "&:hover": {
                backgroundColor: "#eee"
            }
        };
    }
};
const selectErrorStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    control: provided => ({ ...provided, borderColor: "#f00" }),
    option: (provided, state) => {
        return {
            ...provided,
            color: "black",
            backgroundColor: state.isSelected ? colors.primaryColor : "white",
            "&:hover": {
                backgroundColor: "#eee"
            }
        };
    }
};

class DialogEditTask extends React.Component<Props, State> {
    props: Props;
    state: state = {
        modified: false,
        employeeItems: {
            manual: [],
        },
        title: "",
        description: "",
        done: false,

        titleError: false,
        descriptionError: false,
        employeeError: false,
        helperText: "",
        showThumb: true,
    };

    componentDidMount() {
    }

    deleteTask = (task) => {
        apiDeleteTaskById(task)
            .then((entities) => {
                const newList = this.state.tasks.filter((myTask) => (myTask.id !== task));
                this.setState({
                    tasks: newList
                });

            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while deleting tasks'
                    )}: ` + error.message
                )
                throw error;
            });

    }
    checkFormData = () => {
        if (! this.state.title) {
            this.setState({
                titleError: true,
                descriptionError: false,
                employeeError: false,
                helperText: i18n.t('Enter a title')
            });
            return false;
        }
        if (! this.state.description) {
            this.setState({
                titleError: false,
                descriptionError: true,
                employeeError: false,
                helperText: i18n.t('Enter a description')
            });
            return false;
        }
        if (this.props.task === null && this.state.employeeItems.manual.length === 0) {
            this.setState({
                titleError: false,
                descriptionError: false,
                employeeError: true,
                helperText: i18n.t('Select an employee')
            });
            return false;
        }

        return true;
    }
    createPostData = () => {
        let user = null;
        let taskFeedback = null;
        let inspectionAssign = null;
        let incident = null;
        if (this.props.task) {
            user = this.props.task.user;
            taskFeedback = this.props.task.taskFeedback;
        } else {
            taskFeedback = {
                photos: null,
                feedbackText: null,
                done: false
            }
        }
        if (this.state.employeeItems.manual.length > 0) {
            user = this.state.employeeItems.manual[0];
        }

        if (this.props.inspectionAssignId) {
            inspectionAssign = {
                id: this.props.inspectionAssignId
            };
        }
        if (this.props.incidentId) {
            incident = {
                id: this.props.incidentId
            };
        }
        taskFeedback.done = this.state.done

        let postData = {
            title: this.state.title,
            description: this.state.description,
            user: {
                id: user.id,
                firstname: user.firstname,
                middlename: user.middlename,
                lastname: user.lastname
            },
            inspectionAssign: inspectionAssign,
            incident: incident,
            taskFeedback: taskFeedback,
            deleted: false,
        };
        // To update a task set the id
        if (this.props.task) {
            postData.id = this.props.task.id
        }

        return postData;
    }
    handleSubmit = () => {
        // if (! this.state.modified) {
        //     this.props.handleClose();
        // }
        if (! this.checkFormData()) {
            return;
        }

        let postData = this.createPostData();

        apiAddUpdateTask(postData)
            .then((entities) => {
                this.props.callback(entities.body);
            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while deleting tasks'
                    )}: ` + error.message
                )
                throw error;
            });

        this.clearState();
        this.props.handleClose();

    }
    handleClose = () => {
        this.clearState();
        this.props.handleClose();
    }

    employeeClicked = (user) => (event) => {
        const items = this.state.employeeItems;
        items.manual = [];
        items.manual.push(this.props.employees.byId[event.value]);

        this.setState(
            {
                employeeItems: items,
                modified: true,
                employeeError: false,
            }
        );
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.task !== this.props.task) {
            // Search for employee to set defaultValue in Employee Select
            if (this.props.task) {
                // Fill form with values from task
                const defaultEmployee = {
                    value: this.props.task.user.id,
                    label: this.props.task.user.fullname
                }
                // Fill the form
                this.setState({
                    title: this.props.task.title,
                    description: this.props.task.description,
                    defaultEmployee: defaultEmployee,
                    done: this.props.task.taskFeedback.done
                });
            } else {
                if (this.props.task == null) {
                    // Clear the form
                    this.setState({
                        title: '',
                        description: '',
                        defaultEmployee: null,
                        done: false
                    });
                }
            }
        }
    }

    // General method
    handleEditInput = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
            modified: true
        })
    }

    handleSwitch = (event) => {
        this.setState({
            done: !this.state.done,
            modified: true
        });

    }
    getTitle = () => {
        if (this.props.task == null) {
            // Add mode
            return i18n.t('Add Task');
        }
        if (this.props.viewOnly) {
            return i18n.t('View Task');
        }
        return i18n.t('Edit Task');
    }
    toggleThumb = () => {
        this.setState({
            showThumb: !this.state.showThumb
        });
    }

    clearState = () => {
        this.setState({
                modified: false,
                title: "",
                description: "",
                done: false,

                titleError: false,
                descriptionError: false,
                employeeError: false,
                helperText: "",
                showThumb: true,
        });
    }



    render() {
        const { t } = i18n;
        const { employees } = this.props;

        return (
            <Dialog
                fullScreen
                maxWidth={false}
                open={this.props.open ?? false}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                className={classes.root}
            >
                <AppBar   className={classes.appBar}>
                    <Toolbar disableGutters className={classes.toolbar}>
                        <IconButton
                            className={classes.appBarButton}
                            color="inherit"
                            onClick={this.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            type="title"
                            color="inherit"
                            className={classes.flex}
                        >
                            {this.getTitle()}
                        </Typography>
                        <Button

                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            disabled={this.props.viewOnly}
                        >
                            {t('Send')}
                        </Button>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.content}>


                    <div className={classes.formWrapper}>
                        <FormControl className={classes.inputElement}>
                            <Select
                                className={classes.Select}
                                styles={this.state.employeeError ? selectErrorStyles : selectStyles}
                                label={t('Select single Employee')}
                                closeOnSelect={false}
                                isDisabled={this.props.viewOnly}
                                onChange={this.employeeClicked(0)}
                                defaultValue={this.state.defaultEmployee}
                                options={employees.allIds
                                    .map((id) => employees.byId[id])
                                    .filter((empl) => empl.state === 'ACTIVE' || empl.extern === true)
                                    // .filter(empl=> this.grayOutComp(empl)===false)
                                    .sort(function (a, b) {
                                        if (a.firstname.toLowerCase() < b.firstname.toLowerCase())
                                            return -1;
                                        if (a.firstname.toLowerCase() > b.firstname.toLowerCase())
                                            return 1;
                                        if (a.lastname.toLowerCase() < b.lastname.toLowerCase())
                                            return -1;
                                        if (a.lastname.toLowerCase() > b.lastname.toLowerCase())
                                            return 1;
                                        return 0;
                                    })
                                    .map((c) => ({
                                        value: c.id,
                                        label: c.fullname,
                                    }))}
                                placeholder={`${t(
                                    'Select employees'
                                )} `}
                                removeSelected={true}
                                rtl={false}
                            />
                        </FormControl>

                        <FormControl className={classes.inputElement}>
                            <TextField
                                error={this.state.titleError}
                                helperText={this.state.titleError ? this.state.helperText : ''}
                                style={{ backgroundColor: 'white', }}
                                fullWidth={true}
                                disabled={this.props.viewOnly}
                                id='title'
                                label={t('Title')}
                                onChange={this.handleEditInput}
                                value={this.state.title}
                                autoFocus={true}
                                onFocus={function (e) {
                                    let val = e.target.value;
                                    e.target.value = '';
                                    e.target.value = val;
                                }}
                            >Title</TextField>
                        </FormControl>

                        <FormControl className={classes.inputElement}>
                            <TextField
                                error={this.state.descriptionError}
                                helperText={this.state.descriptionError ? this.state.helperText : ''}
                                style={{ backgroundColor: 'white', }}
                                fullWidth={true}
                                disabled={this.props.viewOnly}
                                id='description'
                                onChange={this.handleEditInput}
                                value={this.state.description}
                                autoFocus={true}
                                multiline={true}
                                minRows={5}
                                label={t('Description')}
                                onFocus={function (e) {
                                    let val = e.target.value;
                                    e.target.value = '';
                                    e.target.value = val;
                                }}
                            >Title</TextField>
                        </FormControl>




                        {this.props.task && this.props.task.taskFeedback.done && (
                            <div className={classes.cardWrapper}>
                                <Card className={classes.responseCard}
                                      variant="elevation"
                                      elevation={2}
                                      raised={true}
                                >
                                    <CardContent className={classes.responseCardContent}>
                                        <div className={classes.wrapContainer}>
                                            <div className={classes.responseFrom}>{t('From: ')}{this.props.task.user.fullname}</div>
                                            <div className={classes.responseFrom}>{t('Date: ')}{moment(this.props.task.dateResolved).format('LLL')}</div>

                                        </div>

                                        <FormControl>
                                            <TextField className={classes.message}
                                                       error={this.state.descriptionError}
                                                       helperText={this.state.descriptionError ? this.state.helperText : ''}
                                                       style={{ backgroundColor: 'white', }}
                                                       fullWidth={true}
                                                       id='feedbackText'
                                                       onChange={this.handleEditInput}
                                                       value={this.props.task.taskFeedback.feedbackText}
                                                       placeholder={t('taskFeedbackTextHelper')}
                                                       autoFocus={true}
                                                       multiline={true}
                                                       minRows={5}
                                                       label={t('Explanation')}
                                                       disabled={true}
                                                       onFocus={function (e) {
                                                           let val = e.target.value;
                                                           e.target.value = '';
                                                           e.target.value = val;
                                                       }}
                                            />
                                        </FormControl>

                                        <div>
                                            {this.props.task && this.props.task.taskFeedback && this.props.task.taskFeedback.photos &&
                                                this.props.task.taskFeedback.photos.length > 0 && (
                                                    <div
                                                        style={{
                                                            maxWidth: '550px',
                                                            marginTop: '16px',
                                                        }}
                                                    >
                                                        {this.props.task && this.props.task.taskFeedback.photos.map(
                                                            (photo) => {
                                                                return (
                                                                    this.state.showThumb ?
                                                                            <img className={classes.photoThumb}
                                                                                 src={ photo.url}
                                                                                 alt=""
                                                                                 onClick={this.toggleThumb}
                                                                            />

                                                                            :
                                                                            <img className={classes.photoLarge}
                                                                                 src={ photo.url}
                                                                                 alt=""
                                                                                 onClick={this.toggleThumb}
                                                                            />

                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                        </div>

                                        <FormControl className={classes.inputElement}>
                                            <FormControlLabel
                                                label={t('Taak afgerond')}
                                                control={<Switch
                                                    checked={ this.state.done ?? false }
                                                    onChange={this.handleSwitch}
                                                    id='done'
                                                    disabled={this.props.viewOnly}
                                                />}
                                            />
                                        </FormControl>

                                    </CardContent>
                                </Card>


                            </div>

                        )}





                    </div>
                </DialogContent>
            </Dialog>


        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DialogEditTask);
