// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './EmployeeOverview.css';
import { push } from 'connected-react-router';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Fab, InputLabel, Select, MenuItem, FormControl,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Archive from '@mui/icons-material/Archive';
import { List } from 'immutable';
import jwtDecode from 'jwt-decode';

import {
    parseImport,
    acknowledgeImport,
    clearDiff,
    inviteEmployee,
    getEmployeePIN,
    disableEmployee,
    editEmployee,
    upsertEmployee,
    deleteEmployee,
    switchRoleEmployee,
    fetchSubscriptions,
    upgradeSubscription, getCurrentEmployee, pushCrumbPath,
} from '../../../actions';
import EmployeeDiffRow from '../../Employee/EmployeeOverview/EmployeeDiffRow';
import AppModule from '../../AppModule';
import EnhancedTable from '../../EnhancedTable';
import classnames from 'classnames';


import DialogChoose from '../../DialogChoose';
import ConfirmDialog from '../../ConfirmDialog';

import { withRouter } from 'react-router';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import UpgradeDialog from '../../Abonnement/UpgradeDialog/UpgradeDialog';
import getRoles from '../../../selectors/getRoles';
import { inProgress } from '../../../selectors';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { getLanguages } from '../../../lib/utils';
import classes from './EmployeeOverview.module.scss';
import colors from "../../../lib/themes/theme.scss";
import i18n from "../../../i18n";

type Props = {
    loading: boolean,
    employees: Array<any>,
    user: mixed,
    parseImport: (file: any) => void,
    acknowledgeImport: (file: any) => void,
    clearDiff: () => void,
    getEmployeePIN: (employeeId: number) => void,
    inviteEmployee: (employeeId: number) => void,
    disableEmployee: (employee: TEmployee, t: any) => void,
    deleteEmployee: (employee: TEmployee) => void,
    editEmployee: (employee?: TEmployee) => void,
    startEditEmployee: (employee: TEmployee) => void,
    onSave: (employee?: TEmployee) => void,
    editemployee: TEmployee,
    inProgress: boolean,
    diff: { removed: List<TEmployee>, added: List<TEmployee> },
    display: string,
};

type State = {
    showDeletedEmployees: boolean,
    showUpload: boolean,
    showDialogPin: boolean,
    showDialogChoose: boolean,
    showConfirmDialog: boolean,
    showUpgradeDialog: boolean,
    subscriptionUpgraded: boolean,
    emplToDelete?: TEmployee,
    searchtext: string,
    page: number,
    rowsPerPage: number,
    startPage: number,
    startRowsPerPage: number,
};


//Rare methode. Als je hierin een error krijgt werken de props niet meer zonder enige waarschuwing!
const getSubscriptionUpgrade = (subscription, subscriptionStubs) => {
    if (subscription && subscriptionStubs && subscriptionStubs.prijzen) {
        const prijzen = subscriptionStubs.prijzen;
        for (let i = 0; i < prijzen.length; i++) {
            const prijs = prijzen[i];
            if (prijs.maxEmployees > subscription.maxEmployees) {
                return prijs;
            }
        }

        const maxPrijs = prijzen[prijzen.length - 1]; // Max grootte
        maxPrijs.maxEmployees = -1;
        return maxPrijs;
    }
    return null;
};

const mapStateToProps = (store) => {
    const subscription = store.drafts.employees.currentUser
        ? store.drafts.employees.currentUser.subscription
        : null;

    return {
        loading: !inProgress(store),
        diff: store.drafts.employees.diff,
        inProgress: store.drafts.employees.inProgress,
        user: jwtDecode(store.auth.token),
        editemployee: store.drafts.employees.editemployee,
        deleteemployee: store.drafts.employees.deleteemployee,
        subscription: subscription,
        subscriptionStubs: store.subscription.subscriptionStubs,
        subscriptionUpgrade: getSubscriptionUpgrade(
            subscription,
            store.subscription.subscriptionStubs
        ),
        roles: getRoles(store),
        subscriptionHolder: store.dashboard.dashboard.subscriptionHolderName,
        currentEmpl: store.drafts.employees.currentUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        parseImport: bindActionCreators(parseImport, dispatch),
        acknowledgeImport: bindActionCreators(acknowledgeImport, dispatch),
        getEmployeePIN: bindActionCreators(getEmployeePIN, dispatch),
        getCurrentEmployee: bindActionCreators(getCurrentEmployee, dispatch),
        inviteEmployee: bindActionCreators(inviteEmployee, dispatch),
        disableEmployee: bindActionCreators(disableEmployee, dispatch),
        deleteEmployee: bindActionCreators(deleteEmployee, dispatch),
        editEmployee: bindActionCreators(editEmployee, dispatch),
        switchRoleEmployee: bindActionCreators(switchRoleEmployee, dispatch),
        fetchSubscriptions: bindActionCreators(fetchSubscriptions, dispatch),
        upgradeSubscription: bindActionCreators(upgradeSubscription, dispatch),
        newEmployee: () => {
            dispatch(push('/medewerkers/aanmaken'));
        },
        clearDiff: () => {
            dispatch(clearDiff());
        },
        onSave: (employee, callback) => {
            upsertEmployee(employee, callback)(dispatch);
            dispatch(push('/medewerkers'));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        goToDetailView: (medewerkerId, page, rowsPerPage) => {
            dispatch(push('/medewerker/details/' + medewerkerId + '/' + page + '/' + rowsPerPage));
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },

    };
};

class EmployeeOverview extends React.PureComponent<Props, State> {
    props: Props;
    state: State = {
        showDeletedEmployees: false,
        searchtext: '',
        page: 0,
        rowsPerPage: 10,
        actions: [
            {
                id: 'edit',
                label: 'Wijzigen',
                isVisible: (id) => this.isVisible(id),
            },
            {
                id: 'invite',
                label: 'Uitnodigen voor de app',
                isVisible: (id) => !this.isInvited(id),
            },
            {
                id: 'invite',
                label: 'Opnieuw uitnodigen voor de app',
                isVisible: (id) => this.isInvited(id),
            },
            {
                id: 'getPIN',
                label: 'Vraag PIN',
                isVisible: (id) => true,
            },
            {
                id: 'switchKAM',
                label: 'Maak KAM',
                isVisible: (id) => !this.isKam(id),
            },
            {
                id: 'switchKAM',
                label: 'Ontneem KAM rol',
                isVisible: (id) =>
                    this.isKam(id) &&
                    !this.isSubscriptionHolder(id) &&
                    !this.isCurrentUser(id),
            },
            {
                id: 'disable',
                label: 'Deactiveer',
                isVisible: (id) => {
                    return (
                        this.isInvited(id) &&
                        !this.isCurrentUser(id) &&
                        !this.isSubscriptionHolder(id)
                    );
                },
            },
            {
                id: 'delete',
                label: 'Gebruiker verwijderen',
                isVisible: (id) => {
                    return (
                        !this.isCurrentUser(id) &&
                        this.isDeletable(id) &&
                        !this.isSubscriptionHolder(id)
                    );
                },
            },
        ],
        columns: [
            { id: 'externalId', numeric: false, size: '8%', label: 'Nr' },
            { id: 'role', numeric: false, size: '5%', label: 'Rol' },
            { id: 'firstname', numeric: false, size: '10%', label: 'Naam' },
            {
                id: 'lastname',
                numeric: false,
                size: '13%',
                label: 'Achternaam',
            },
            { id: 'username', numeric: false, size: '22%', label: 'E-mail' },
            {
                id: 'appVersion',
                numeric: false,
                size: '10%',
                label: 'App versie',
            },
            ...(this.props.roles && this.props.roles.includes('ADMIN')
                ? [
                      {
                          id: 'whitelabelPackageName',
                          numeric: false,
                          size: '14%',
                          label: 'Whitelabel App',
                      },
                  ]
                : []),
            {
                id: 'phonenumber',
                numeric: false,
                size: '10%',
                label: 'Telefoonnummer',
            },
            {
                id: 'state',
                numeric: false,
                size: '120px',
                label: 'Status',
                classes: 'status',
            },
            { id: 'import', numeric: false, size: '64px', label: 'Type' },
        ],
        showUpload: false,
        showDialogPin: false,
        showDialogChoose: false,
        showConfirmDialog: false,
        showUpgradeDialog: false,
        showKamUpgradeDialog: false,
        subscriptionUpgraded: false,
        showInviteDialog: false,
        languageValue: null,
        rowId: null,
    };

    showDialogPin = (pin) => {
            this.setState({
                pin: pin,
                showDialogPin: true
            });
    }
    closeDialogPin = () => {
        this.setState({
            pin: "",
            showDialogPin: false
        });
    }

    getEmployee = (employeeId: number): ?TEmployee => {
        return this.props.employees.find((empl) => empl.id === employeeId);
    };

    isVisible = (rowId) => {
        const empl = this.getEmployee(rowId);
        if (empl) {
            if (empl.accountEnabled) {
                return false;
            }
            return empl.importType !== 'IMPORT';
        }
        return true;
    };

    isKam = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl && empl.roles) {
            const roles = empl.roles;
            return ~roles.indexOf('COORDINATOR');
        }
        return false;
    };

    isAdmin() {
        if (this.props.user && this.props.user.roles) {
            return this.props.user.roles.indexOf('ADMIN');
        }
        return false;
    };


    isSubscriptionHolder = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl && empl.roles) {
            const roles = empl.roles;
            return ~roles.indexOf('SUBSCRIPTIONHOLDER');
        }
        return false;
    };

    isDeletable = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl.accountEnabled) {
            return false;
        }

        if (empl) {
            return empl.importType === 'MANUAL';
        }
        return false;
    };

    isCurrentUser = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl) {
            return empl.id === this.props.user.id;
        }
        return false;
    };

    isInvited = (rowId) => {
        const empl = this.getEmployee(rowId);

        if (empl && !~empl.roles.indexOf('COORDINATOR')) {
            return empl.state === 'ACTIVE';
        } else if (empl && ~empl.roles.indexOf('COORDINATOR')) {
            if (empl.invited === false) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    };

    isActive = (rowId) => {
        const empl = this.getEmployee(rowId);
        return empl.state === 'ACTIVE';
    }

    handleAction = (event, action) => {
        const { t } = this.props;
        if (action.id === 'edit') {
            this.props.startEditEmployee(action.rowId);
        } else if (action.id === 'invite') {
            const { subscription, employees, roles } = this.props;

            const activeEmployees = employees.filter(
                (e) => e.state === 'ACTIVE'
            );

            if (
                this.getEmployee(action.rowId).state !== 'ACTIVE' &&
                activeEmployees.length >= subscription.maxEmployees &&
                subscription.maxEmployees !== -1
            ) {
                if (roles.indexOf('SUBSCRIPTIONHOLDER') > -1) {
                    this.setState({
                        showUpgradeDialog: true,
                    });
                } else {
                    this.setState({
                        showKamUpgradeDialog: true,
                    });
                }
            } else {
                this.setState({ showInviteDialog: true, rowId: action.rowId });
                // this.props.inviteEmployee(action.rowId);
            }
        } else if (action.id === 'disable') {
            const empl = this.getEmployee(action.rowId);
            if (empl) {
                this.props.disableEmployee(empl, t);
            }
        } else if (action.id === 'delete') {
            const empl = this.getEmployee(action.rowId);
            if (empl) {
                this.setState({ emplToDelete: empl });
                this.showConfirmDialog();
            }
        } else if (action.id === 'switchKAM') {
            const empl = this.getEmployee(action.rowId);
            if (empl) {
                if (!~empl.roles.indexOf('COORDINATOR')) {
                    this.setState({ openDialog: true, empl: empl });
                } else {
                    this.props.switchRoleEmployee(empl, 'COORDINATOR');
                }
            }
        } else if (action.id === 'getPIN') {
            const empl = this.getEmployee(action.rowId);
            if (empl) {
                this.props.getEmployeePIN(action.rowId, this.showDialogPin );
            }
        }
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    setPage = (page) => {
        console.log("INFO: EmployeeOverview page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: EmployeeOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }
    mapEmployeeToRow = (employee) => {
        const { t } = this.props;
        return {
            ...employee,
            state: this.employeeStateToText(employee.state, employee.accountEnabled),
            role: this.employeeRoleToText(employee.roles),
            username: employee.extern
                ? employee.externalusername
                : employee.username,
            lastname:
                employee.lastname +
                (employee.middlename && employee.middlename !== ''
                    ? ', ' + employee.middlename
                    : ''),
            import:
                employee.importType === 'MANUAL' || !employee.importType ? (
                    <div className={'tooltip'}>
                        <span className={'tooltiptext'}>
                            {t('Manual added')}
                        </span>
                        <PersonAdd   />
                    </div>
                ) : (
                    <div className={'tooltip'}>
                        <span className={'tooltiptext'}>
                            {t('Added trough CSV import')}
                        </span>
                        <Archive   />
                    </div>
                ),
        };
    };

    employeeRoleToText = (roles) => {
        if (roles && ~roles.indexOf('COORDINATOR')) {
            return 'KAM';
        }
        return '';
    };

    employeeStateToText = (state, accountEnabled) => {
        const { t } = this.props;
        let text = '';

        switch (state) {
            case 'ADDED':
                text = t('Inactive');
                break;
            case 'ACTIVE':
                text = t('Active');
                break;
            case 'INACTIVE':
                text = t('Inactive');
                break;
            default:
        }
        if (accountEnabled) {
            text = t('Deleted');
        }


        return <div className={'border-text ' + state}>{text}</div>;
    };

    openUploadDialog = () => {
        this.setState({
            showUpload: true,
        });
        this.closeChooseDialog();
    };

    openChooseDialog = () => {
        const activeEmployees = this.props.employees.filter(
            (e) => e.state === 'ACTIVE'
        );
        if (this.props.subscription && this.props.subscription.maxEmployees) {
            if ((activeEmployees.length >= this.props.subscription.maxEmployees) && this.props.subscription.maxEmployees !== -1) {
                this.setState({ showUpgradeDialog: true });
                return;
            }

        }
        this.setState({
            showDialogChoose: true,
        });
    };

    closeChooseDialog = () => {
        this.setState({
            showDialogChoose: false,
        });
    };

    handleUpload = (file: any) => {
        this.props.parseImport(file);
    };

    handleSwitchChange = () => {
        this.setState({
            showDeletedEmployees: !this.state.showDeletedEmployees,
        });
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };


    acknowledgeImport = (file: any) => {
        this.props.acknowledgeImport(file);
        this.closeChooseDialog();
        this.props.clearDiff();
    };

    hideDialogChoose = () => {
        this.props.clearDiff();
        this.setState({
            showDialogChoose: false,
        });
    };

    mapToDiffRow = (list: List<TEmployee>) => {
        return list.map((employee) => (
            <EmployeeDiffRow employee={employee} key={employee.externalId} />
        ));
    };

    mapToConflictRow = (list: List<TEmployee>) => {
        return list.map((employee) => (
            <EmployeeDiffRow
                employee={employee}
                key={employee.externalId}
                conflictRow={employee.conflict}
            />
        ));
    };

    componentDidMount() {
        this.props.fetchSubscriptions();
        this.props.fetchCurrentUser();

        if (this.props.location.hash === '#new') {
            this.openChooseDialog();
        }

        this.setState({
            actions: [
                {
                    id: 'edit',
                    label: 'Change',
                    isVisible: (id) => this.isVisible(id),
                },
                {
                    id: 'invite',
                    label: 'Invite to app',
                    isVisible: (id) => !this.isInvited(id),
                },
                {
                    id: 'invite',
                    label: 'Invite again to app',
                    isVisible: (id) => this.isInvited(id),
                },
                {
                    id: 'switchKAM',
                    label: 'Make KAM',
                    isVisible: (id) => !this.isKam(id),
                },
                {
                    id: 'switchKAM',
                    label: 'Take away KAM role',
                    isVisible: (id) =>
                        this.isKam(id) &&
                        !this.isSubscriptionHolder(id) &&
                        !this.isCurrentUser(id),
                },
                {
                    id: 'disable',
                    label: 'Deactivate',
                    isVisible: (id) => {
                        return (
                            this.isActive(id) &&
                            !this.isCurrentUser(id) &&
                            !this.isSubscriptionHolder(id)
                        );
                    },
                },
                {
                    id: 'getPIN',
                    label: 'Get PIN',
                    isVisible: (id) => this.isInvited(id),
                },
                {
                    id: 'delete',
                    label: 'Delete user',
                    isVisible: (id) => {
                        return (
                            !this.isCurrentUser(id) &&
                            this.isDeletable(id) &&
                            !this.isSubscriptionHolder(id)
                        );
                    },
                },
            ],
            columns: [
                { id: 'externalId', numeric: false, size: '8%', label: 'Nr' },
                { id: 'role', numeric: false, size: '5%', label: 'Role' },
                {
                    id: 'language',
                    numeric: false,
                    size: '10%',
                    label: 'Language',
                },
                {
                    id: 'firstname',
                    numeric: false,
                    size: '10%',
                    label: 'Name',
                },
                {
                    id: 'lastname',
                    numeric: false,
                    size: '13%',
                    label: 'Last name',
                },
                {
                    id: 'username',
                    numeric: false,
                    size: '22%',
                    label: 'Emailadres',
                },
                {
                    id: 'appVersion',
                    numeric: false,
                    size: '10%',
                    label: 'App version',
                },
                ...(this.props.roles && this.props.roles.includes('ADMIN')
                    ? [
                          {
                              id: 'whitelabelPackageName',
                              numeric: false,
                              size: '14%',
                              label: 'Whitelabel App',
                          },
                      ]
                    : []),
                {
                    id: 'phonenumber',
                    numeric: false,
                    size: '10%',
                    label: 'Phone number',
                },
                {
                    id: 'state',
                    numeric: false,
                    size: '120px',
                    label: 'State',
                    classes: 'status',
                },
                {
                    id: 'import',
                    numeric: false,
                    size: '64px',
                    label: 'Type',
                },
            ],
        });

        // If url params rows and page go to right page
        if (this.props.location && this.props.location.search && this.props.location.search.includes("page")) {
            const jumpToPage = new URLSearchParams(this.props.location.search).get("page");
            console.log("INFO: page from SearchParams: " + jumpToPage);
            this.setState({ startPage: Number(jumpToPage)});
        }
        if (this.props.location && this.props.location.search && this.props.location.search.includes("rows")) {
            const startRowsPerPage = new URLSearchParams(this.props.location.search).get("rows");
            console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
            this.setState({ startRowsPerPage: Number(startRowsPerPage)});

        }

    }

    onRowClick = (row, event) => {
        if (row.id) {
            this.props.pushCrumbPath({crumb: { name: i18n.t('Employee'), link: `/medewerkers?page=${this.state.page}&row=${this.state.rowsPerPage}`}})
            this.props.goToDetailView(row.id, this.state.page, this.state.rowsPerPage);
        }
    };

    closeUpgradeDialog = () => {
        this.setState({
            showUpgradeDialog: false,
            subscriptionUpgraded: false,
        });
    };

    closeConfirmDialog = () => {
        this.setState({ openDialog: false, empl: null });
    };

    confirmKAM = () => {
        this.props.switchRoleEmployee(this.state.empl, 'COORDINATOR');
        this.setState({ openDialog: false, empl: null });
    };

    upgradeSubscription = () => {
        this.props.upgradeSubscription((err) => {
            if (!err) {
                this.props.fetchCurrentUser();
                this.setState({
                    subscriptionUpgraded: true,
                });
            }
        });
    };

    employeeSorter = () => {
        return {
            externalId: (a, b) => {
                const numberA = parseInt(a.externalId, 10);
                const numberB = parseInt(b.externalId, 10);

                if (isNaN(numberA) && !isNaN(numberB)) {
                    return 1;
                } else if (!isNaN(numberA) && isNaN(numberB)) {
                    return -1;
                } else if (!isNaN(numberA) && !isNaN(numberB)) {
                    return numberA - numberB;
                } else {
                    return a.externalId > b.externalId ? 1 : -1;
                }
            },
            role: (a, b) => {
                if (
                    ~a.roles.indexOf('COORDINATOR') &&
                    !~b.roles.indexOf('COORDINATOR')
                ) {
                    return 1;
                } else if (
                    !~a.roles.indexOf('COORDINATOR') &&
                    ~b.roles.indexOf('COORDINATOR')
                ) {
                    return -1;
                }
                return 0;
            },
            firstname: (a, b) => {
                a = a.firstname.toLowerCase();
                b = b.firstname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            lastname: (a, b) => {
                a = a.lastname.toLowerCase();
                b = b.lastname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            import: (a, b) => {
                a = a.importType;
                b = b.importType;

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            appVersion: (a, b) => {
                let first = a.appVersion;
                let second = b.appVersion;

                if (
                    (first === null || first === '' || first === undefined) &&
                    (second === null || second === '' || second === undefined)
                ) {
                    return 0;
                } else if (
                    first === null ||
                    first === '' ||
                    first === undefined
                ) {
                    return 1;
                } else if (
                    second === null ||
                    second === '' ||
                    second === undefined
                ) {
                    return -1;
                }

                let versionRegex = /([0-9.]*)/;
                let versionA = (first.match(versionRegex) || [0])[0];
                let versionB = (second.match(versionRegex) || [0])[0];

                if (versionA < versionB) {
                    return -1;
                } else if (versionA === versionB) {
                    // Let's sort by the other values
                    let deviceA = (first.match(
                        /(?=[0-9.]*)(?! - )([a-zA-Z]+)/g
                    ) || [''])[0];
                    let deviceB = (second.match(
                        /(?=[0-9.]*)(?! - )([a-zA-Z]+)/g
                    ) || [''])[0];

                    if (deviceA > deviceB) {
                        return 1;
                    } else if (deviceA < deviceB) {
                        return -1;
                    } else {
                        return this.hasHigherOperatingSystemVersion(
                            first,
                            second
                        )
                            ? 1
                            : -11;
                    }
                } else {
                    return 1;
                }
            },
            username: null,
            phonenumber: null,
        };
    };

    hasHigherOperatingSystemVersion = (a, b) => {
        let versionRegex = /(?![a-zA-Z0-9].*) - ([a-zA-Z].*) ([0-9.]*)/;
        let versionA = a.match(versionRegex);
        let versionB = b.match(versionRegex);

        if (
            versionA !== null &&
            versionA.length > 1 &&
            versionB !== null &&
            versionB.length > 1
        ) {
            // We've got versions, let's compare
            let versionCodeA = versionA[2].split('.');
            let versionCodeB = versionB[2].split('.');

            if (versionCodeA.length === 0) {
                return true;
            } else if (versionCodeB.length === 0) {
                return false;
            }

            for (
                let i = 0;
                i < Math.min(versionCodeA.length, versionCodeB.length);
                i++
            ) {
                if (Number(versionCodeA[i]) > Number(versionCodeB[i])) {
                    return true;
                } else if (versionCodeA === versionCodeB) {
                    // They are equal, we need to continue the loop
                }
            }

            return false;
        } else if (versionA === null || versionA.length <= 1) {
            return false;
        } else if (versionB === null || versionB.length <= 1) {
            return true;
        } else {
            return false;
        }
    };

    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    handleChangeLanguage = (event) => {
        this.setState({ languageValue: event.target.value });
    };

    handleInvite = () => {
        const { rowId, languageValue } = this.state;
        this.props.inviteEmployee(rowId, null, languageValue);
        this.handleCloseInviteDialog();
    };

    handleCloseInviteDialog = () => {
        this.setState({
            showInviteDialog: false,
            rowId: null,
            languageValue: null,
        });
    };

    render() {
        const {
            loading,
            employees,
            subscription,
            subscriptionUpgrade,
            subscriptionHolder,
            t,
        } = this.props;

        const {
            actions,
            columns,
            showUpgradeDialog,
            subscriptionUpgraded,
            showKamUpgradeDialog,
            showInviteDialog,
            languageValue,
            showDeletedEmployees,
        } = this.state;

        const languages = getLanguages(true);

        const totalEmployees =
            employees.length +
            ' ' +
            t('employee') +
            (employees.length === 1 ? '' : 's');
        const activeEmployees =
            '(' +
            employees.filter((e) => e.state === 'ACTIVE').length +
            ' ' +
            t('active') +
            ')';
        const searchresults = employees.filter((row) => {
            if (!showDeletedEmployees && row.accountEnabled) {
                return false;
            }
            return (
                (row.fullname &&
                    row.fullname
                        .replace('  ', ' ')
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1) ||
                (row.username &&
                    row.username
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1) ||
                (row.phonenumber &&
                    row.phonenumber
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1) ||
                (row.externalId &&
                    row.externalId
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1) ||
                (row.roles &&
                    row.roles.includes('COORDINATOR') &&
                    this.state.searchtext.toLowerCase().includes('kam'))
            );
        });

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder={t('Search in employees')}
                onSearchChange={this.handleSearch}
                showEmployeeSwitch={this.props.roles && this.props.roles.includes('ADMIN') ? true : false}
                handleEmployeeSwitchChange={this.handleSwitchChange}
                showDeletedEmployees={this.showDeletedEmployees}
                title={
                    totalEmployees +
                    ' ' +
                    activeEmployees +
                    (this.state.searchtext.length > 0
                        ? (searchresults.length === 1
                              ? ' - ' +
                                searchresults.length +
                                ' ' +
                                t('searchresults')
                              : '') +
                          (searchresults.length > 1 ||
                          searchresults.length === 0
                              ? ' - ' +
                                searchresults.length +
                                ' ' +
                                t('searchresults')
                              : '')
                        : '')
                }
            />
        );

        return (
            <div>
                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    <UpgradeDialog
                        open={showUpgradeDialog ? showUpgradeDialog : false}
                        handleClose={this.closeUpgradeDialog}
                        onUpgrade={this.upgradeSubscription}
                        subscription={subscription}
                        subscriptionUpgrade={subscriptionUpgrade}
                        subscriptionUpgraded={subscriptionUpgraded}
                    />
                    <Dialog
                        open={this.state.showDialogPin ? this.state.showDialogPin : false }
                        onClose={this.props.handleClosePinDialog}
                    >
                        <DialogTitle className="title">
                            {t('PIN')}
                        </DialogTitle>
                        <DialogContent>
                            {t('PIN:  ')}
                            {this.state.pin}
                        </DialogContent>
                        <div className="confirmButton-container">
                            <Button
                                className="confirmButton"
                                onClick={this.closeDialogPin}
                            >
                                {t('Close')}
                            </Button>
                        </div>
                    </Dialog>

                    <Dialog
                        onClose={this.handleCloseInviteDialog}
                        open={showInviteDialog ? showInviteDialog : false}
                        classes={{
                            paper: classes.selectableSelect,
                        }}
                    >
                        <DialogTitle>{t('Invite user')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('Select language for invite email')}.
                            </DialogContentText>
                            <FormControl className={classes.formControl}>
                                <InputLabel
                                    className={classes.color}
                                    htmlFor="language"
                                    color="secondary"
                                >
                                    {t('Language')}
                                </InputLabel>
                                <Select
                                    variant='standard'
                                    value={languageValue}
                                    onChange={this.handleChangeLanguage}
                                    name="language"
                                    autoWidth
                                    className={classes.LanguageDropdown}
                                >
                                    {languages.map((lang) => (
                                        <MenuItem
                                            key={lang}
                                            value={lang}>{t(lang)}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                        </DialogContent>
                        <DialogActions>
                            <Button sx={{color: colors.secondaryColor}} onClick={this.handleInvite}>
                                {t('Ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        onClose={() => {
                            this.setState({ showKamUpgradeDialog: false });
                        }}
                        open={showKamUpgradeDialog ? showKamUpgradeDialog : false}
                    >
                        <DialogTitle>
                            {t('Can not invite employee')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t('Reached maximum users')}.{' '}
                                {t('Contact subscription holder')}(
                                {subscriptionHolder}){' '}
                                {t('to upgrade subscription')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{color: colors.secondaryColor}}
                                onClick={() => {
                                    this.setState({
                                        showKamUpgradeDialog: false,
                                    });
                                }}
                            >
                                {t('Ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <DialogChoose
                        open={this.state.showDialogChoose ? this.state.showDialogChoose : this.state.showDialogChoose}
                        handleClose={this.hideDialogChoose}
                        onUpload={this.handleUpload}
                        diff={this.props.diff}
                        acknowledgeImport={this.acknowledgeImport}
                        inProgress={this.props.inProgress}
                        mapToDiffRow={this.mapToDiffRow}
                        mapToConflictRow={this.mapToConflictRow}
                        editEmployeeFunction={this.props.editEmployee}
                        editemployee={this.props.editemployee}
                        onSave={this.props.onSave}
                        display={this.props.display}
                        extern={false}
                        employees={this.props.employees}
                    />
                    <ConfirmDialog
                        open={this.state.showConfirmDialog ? this.state.showConfirmDialog : false}
                        handleClose={this.hideConfirmDialog}
                        deleteemployee={this.state.emplToDelete}
                        deleteEmployeeFunction={this.props.deleteEmployee}
                        display={this.props.display}
                    />
                    <div
                        className={classnames({
                            [classes.button]: true,
                            'mui-fixed': true,
                        })}
                    >
                        <Fab onClick={this.openChooseDialog}>
                            <AddIcon />
                        </Fab>
                    </div>
                    <EnhancedTable
                        hover
                        columns={columns}
                        onClick={this.onRowClick}
                        defaultOrder="desc"
                        defaultOrderBy={'externalId'}
                        rows={searchresults}
                        formatter={this.mapEmployeeToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.employeeSorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t(
                                      'No employees, add employees with plus sign'
                                  )
                        }
                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}

                    />
                    <Dialog
                        open={this.state.openDialog ? this.state.openDialog : false}
                        onClose={this.cancelEdit}
                        TransitionComponent={this.Transition}
                        className="victor"
                    >
                        <DialogTitle className="title">
                            {t('Make KAM employee?')}
                        </DialogTitle>
                        <DialogActions>
                            <Button
                                sx={{color: colors.secondaryColor}}
                                className="button cancel"
                                onClick={this.closeConfirmDialog}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                sx={{color: colors.secondaryColor}}
                                className="button add"
                                onClick={this.confirmKAM}
                            >
                                {t('Make KAM')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </AppModule>
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(EmployeeOverview))
);
