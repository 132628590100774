// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Dialog,
    DialogContent,
    Toolbar,
    Typography,
    AppBar,
    Button,
    IconButton,
    Paper,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Divider,
    List,
    ListItemText,
    ListItem,
    Popover,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide'
import Loader from '../../Loader';
import { inProgress } from '../../../selectors';
import getRoles from '../../../selectors/getRoles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchSubscriptionCategories } from '../../../actions/subscriptionActions';
import { bindActionCreators } from 'redux';
import { upgradeAbonnee } from '../../../actions/subscriptionActions';
import { TSubscription } from '../../../reducers/subscriptionReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './AbonnementCredits.module.scss'

type Props = {
    loading: boolean,
    onUpgraded: () => mixed,
    upgradeAbonnee: (abonnee: TSubscription) => void,
};

type State = {
    warningDialogOpen: boolean,
    subscription: any,
    menuCreditsOpen: boolean,
    creditsAmount: any,
    creditsPrice: any,
    dialogUpgraded: boolean,
    rowMenuAnchorElement: null,
};

const mapStateToProps = (store, props) => {
    //TODO: Get prices from craft
    const credits = [
        { amount: 100, price: 99 },
        { amount: 1000, price: 899 },
        { amount: 5000, price: 3999 },
    ];

    return {
        loading: !inProgress(store),
        roles: getRoles(store),
        credits: credits,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        upgradeAbonnee: bindActionCreators(upgradeAbonnee, dispatch),
        onClose: () => {
            // window.$crisp.push(['do', 'chat:show']);

            dispatch(push('/abonnement'));
        },
        fetchCategories: (id: Number) => {
            dispatch(fetchSubscriptionCategories(id));
        },
    };
};


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AbonnementCredits extends React.Component<Props, State> {
    props: Props;
    state: State = {
        warningDialogOpen: false,
        subscription: this.props.subscription,
        menuCreditsOpen: false,
        rowMenuAnchorElement: null,
        creditsAmount: 100,
        creditsPrice: 99,
    };

    handleClose = (event) => {
        this.props.onClose();
        this.setState({
            subscription: this.props.subscription,
            creditsAmount: 100,
        });
    };

    requestWarningDialogClose = () => {
        this.setState({
            warningDialogOpen: false,
        });
    };

    requestDialogUpgradedClose = () => {
        this.setState({
            dialogUpgraded: false,
        });
        this.props.onClose();
        this.props.onUpgraded();
        window.location.reload();
    };

    handleCreditsChange = (action) => (event) => {
        this.setState({
            menuCreditsOpen: false,
            creditsAmount: action.amount,
            creditsPrice: action.price,
            subscription: {
                ...this.state.subscription,
                credits: this.props.subscription.credits + action.amount,
            },
        });
    };

    openCreditsMenu = (event) => {
        this.setState({
            menuCreditsOpen: true,
            rowMenuAnchorElement: event.currentTarget,
        });
    };

    handleRowMenuCreditsRequestClose = (event) => {
        this.setState({ menuCreditsOpen: false });
    };

    handleUpgrade = (event) => {
        this.props.upgradeAbonnee(this.state.subscription);
        this.setState({ warningDialogOpen: false, dialogUpgraded: true });
    };

    handleUpgradeDialog = (event) => {
        this.setState({
            subscription: {
                ...this.state.subscription,
                credits:
                    this.props.subscription.credits + this.state.creditsAmount,
            },
            warningDialogOpen: true,
        });
    };

    render() {
        const { open, loading, credits, t } = this.props;
        const {
            warningDialogOpen,
            dialogUpgraded,
            subscription,
            creditsAmount,
        } = this.state;

        return (
            <Dialog
                fullScreen
                maxWidth={false}
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <Dialog
                    open={warningDialogOpen}
                    onClose={this.requestWarningDialogClose}
                >
                    <DialogTitle>{t('Buy more credits')}?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('One-off')} {creditsAmount}{' '}
                            {t('Buy credits info')}
                        </DialogContentText>
                        {subscription.stripeId && !subscription.trial && (
                            <DialogContentText>
                                <p></p>
                                {t('auto collection info')}
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                             
                            onClick={this.requestWarningDialogClose}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button   onClick={this.handleUpgrade}>
                            {t('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={dialogUpgraded}
                    onClose={this.requestDialogUpgradedClose}
                >
                    <DialogTitle>{t('New credits added')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Credits application succes')} <br />
                            {t('Credits application mail')} <br />
                            {t('You can now get started right away')}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                             
                            onClick={this.requestDialogUpgradedClose}
                        >
                            {t('Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <AppBar   className={classes.appBar}>
                    <Toolbar disableGutters>
                        <IconButton
                            className={classes.appBarButton}
                            color="inherit"
                            onClick={this.handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            type="title"
                            color="inherit"
                            className={classes.flex}
                        >
                            {t('Buy more credits')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent className={classes.content}>
                    <Loader loading={loading}>
                        <div className={classes.abonnementContent}>
                            <div className={classes.abonnementInfoCard}>
                                <div
                                    style={{
                                        background: '#FFDD4A',
                                        height: '288px',
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '24px',
                                            fontFamily:
                                                'filson-soft,sans-serif',
                                            lineHeight: '24px',
                                            paddingTop: '36px',
                                        }}
                                    >
                                        {creditsAmount} {t('credits for')}
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                fontSize: '64px',
                                                color: '#282D35',
                                                lineHeight: '24px',
                                                textAlign: 'center',
                                                paddingTop: '81px',
                                                fontFamily:
                                                    'filson-soft,sans-serif',
                                            }}
                                        >
                                            €{this.state.creditsPrice}
                                        </div>
                                        <div
                                            style={{
                                                opacity: '0.5',
                                                fontSize: '17px',
                                                color: '#282D35',
                                                lineHeight: '24px',
                                                textAlign: 'center',
                                                paddingTop: '50px',
                                            }}
                                        >
                                            {t('One-off')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.abonnementInfoContent}>
                                <h2 style={{ fontSize: '24px' }}>
                                    {t('Buy more credits')}
                                </h2>
                                <p
                                    style={{
                                        opacity: '0.54',
                                        marginBottom: '50px',
                                    }}
                                >
                                    {t(
                                        'Select amount of credits you want to buy'
                                    )}
                                </p>
                                <Divider />
                                <div style={{ display: 'block' }}>
                                    <p
                                        style={{
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        <b>{t('Buy more credits')}</b>
                                        <IconButton
                                            onClick={this.openCreditsMenu}
                                            style={{
                                                width: '40px',
                                                float: 'right',
                                                marginTop: '-15px',
                                            }}
                                            size="large">
                                            <ExpandMoreIcon />
                                        </IconButton>
                                        <div
                                            style={{
                                                color: '#0091EA',
                                                float: 'right',
                                            }}
                                            onClick={this.openCreditsMenu}
                                        >
                                            {t('One-off')} {creditsAmount}{' '}
                                            {t('credits for')} €
                                            {this.state.creditsPrice}
                                        </div>
                                    </p>
                                </div>
                                <Divider />
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    className={classes.upgradeButton}
                                    onClick={this.handleUpgradeDialog}
                                    disabled={!subscription.credits_setup}
                                >
                                    {t('Buy credits')}
                                </Button>
                            </div>
                        </div>
                        <Paper elevation={0}>
                            <Popover
                                anchorEl={this.state.rowMenuAnchorElement}
                                id={this.uuid + '-rowMenu'}
                                open={this.state.menuCreditsOpen}
                                onClose={
                                    this.handleRowMenuCreditsRequestClose
                                }
                            >
                                <List>
                                    {credits !== null &&
                                        credits.map((action, index) => (
                                            <ListItem
                                                button
                                                key={index}
                                                onClick={this.handleCreditsChange(
                                                    action
                                                )}
                                            >
                                                <ListItemText
                                                    primary={
                                                        action.amount +
                                                        ' ' +
                                                        t('credits')
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Popover>
                        </Paper>
                    </Loader>
                </DialogContent>
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(AbonnementCredits);
