// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {Fab} from '@mui/material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
    deleteToolbox,
    openDialog,
    publishToolbox,
    retractToolbox,
    pushCrumbPath,
    showSnackbar,
} from '../../actions';
import AppModule from '../AppModule';
import EnhancedTable from '../EnhancedTable';
import moment from '../../lib/moment';
import {getPermissions, getToolboxOverviewV2,} from '../../selectors';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import getRoles from '../../selectors/getRoles';
import ConfirmDialog from '../ConfirmDialog';
import FilterHeader from '../FilterHeader';
import $ from 'jquery';
import {percentColors1} from '../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";
import i18n from "i18next";
import {toolboxOverviewInProgress} from "../../selectors/inProgress";
import classes from './ToolboxOverview.module.scss';

type Props = {
    loading: boolean,
    toolboxes: Array<any>,
    sectors: Array<any>,
    categories: Array<any>,
    permissions: any,
    deployToolbox: (toolboxId: string) => void,
    editToolbox: (toolboxId: string) => void,
    publishToolbox: (toolboxId: string) => void,
    retractToolbox: (toolboxId: string) => void,
    deleteToolbox: (toolboxId: string) => void,
    createToolbox: () => void,
    roles: Array<any>,
};

type State = {
    openType: boolean,
    toolboxErrorDialogOpen: boolean,
    toolboxErrorDialogText: string,
    columns: Array<any>,
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean,
    }>,
    searchtext: string,
    typeFilter: any,
    openSector: boolean,
    sectorFilter: any,
    openCategory: boolean,
    categoryFilter: any,
    showConfirmDialog: boolean,
};

const mapStateToProps = (store) => {
    let toolboxes = getToolboxOverviewV2(store);
    const sectors = store.entities.sectors;
    const categories = store.entities.categories;

    return {
        loading: toolboxOverviewInProgress(store) && toolboxes.length === 0,
        toolboxes: toolboxes,
        sectorEntities: store.entities.sectors,
        categoryEntities: store.entities.categories,
        sectors: sectors.allIds.map((id) => sectors.byId[id]),
        categories: categories.allIds.map((id) => categories.byId[id]),
        permissions: getPermissions(store),
        roles: getRoles(store),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        editToolbox: (id) => {
            // window.$crisp.push(['do', 'chat:hide']);

            dispatch(push('/toolboxen/' + id));
        },
        deleteToolbox: (toolboxId) => {
            dispatch(deleteToolbox(toolboxId));
        },
        deployToolbox: (toolbox) => {
            dispatch(openDialog('toolboxDeploy', { id: toolbox }));
        },
        createToolbox: () => {
            dispatch(openDialog('toolboxCreate'));
        },
        pushCrumbPath: (crumb) => {
            dispatch(pushCrumbPath(crumb));
        },
        goToDetailView: (toolboxId, deployedDate, page, rowsPerPage) => {
            dispatch(
                push(
                    '/toolbox/details/' +
                        toolboxId +
                        '#overzicht' +
                        '?page=' + page +
                        '&rowsPerPage=' + rowsPerPage
                )
            );
        },
        publishToolbox: (toolboxId: string, callback: (success: boolean, errorMessage: string) => mixed) => {
            dispatch(publishToolbox(toolboxId, callback));
        },
        showSnackbar: (message: string) => dispatch(
                showSnackbar(message)
            ),
        retractToolbox: (toolboxId: string) => {
            dispatch(retractToolbox(toolboxId));
        },
    };
};

let categories = [];

class ToolboxOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        columns: [],
        actions: [],
        toolboxErrorDialogOpen: false,
        toolboxErrorDialogText: '',
        searchtext: '',
        showConfirmDialog: false,
        typeFilter: 0,
        openSector: false,
        openType: false,
        sectorFilter: 0,
        openCategory: false,
        categoryFilter: 0,
    };

    componentDidMount() {
        const { t } = this.props;

        // NOTE: toolboxes are already fetched by DialogToolboxDeploy

        const versionOrPublished =
            this.props.permissions.publish.toolbox ||
            this.props.roles.includes('ADMIN')
                ? { id: 'published', label: t('Published'), size: '15%' }
                : { id: 'deployed', label: t('Last sent out'), size: '15%' };

        this.setState({
            columns: [
                { id: 'logo', label: '', size: '48px' },
                { id: 'title', label: 'Title', size: '26%' },
                { id: 'sectors', label: 'Sector', size: '12%' },
                { id: 'category', label: 'Category', size: '15%' },
                { id: 'version', label: 'Version', size: '7%' },
                { id: 'date', label: 'Edited', size: '15%' },
                { id: 'language', label: 'Language', size: '15%' },
                versionOrPublished,
            ],
        });

        console.log("INFO: ToolboxOverview: componentDidMount()");
        // If url params rows and page go to right page
        console.log("INFO: hash: " + this.props.location.hash);

        if (this.props.location.hash.includes("overzicht")) {
            const searchString = '?' + this.props.location.hash.split("?")[1]
            console.log("INFO: Custom searchString: " + searchString);

            if (searchString.includes("page")) {
                const jumpToPage = new URLSearchParams(searchString).get("page");
                console.log("INFO: page from SearchParams: " + jumpToPage);
                this.setState({ startPage: Number(jumpToPage)});
                this.setState({ page: Number(jumpToPage)});
            }
            if (searchString.includes("rowsPerPage")) {
                const startRowsPerPage = new URLSearchParams(searchString).get("rowsPerPage");
                console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
                this.setState({ startRowsPerPage: Number(startRowsPerPage)});
                this.setState({ rowsPerPage: Number(startRowsPerPage)});
            }
        }

        let actions = [];

        if (this.props.permissions.toolboxes.edit) {
            actions.push({ id: 'edit', label: 'Change' });
        }

        if (this.props.permissions.toolboxes.deploy) {
            actions.push({
                id: 'deploy',
                label: 'Send out',
                isVisible: (id) => this.isPublished(id),
            });
        }

        if (this.props.permissions.toolboxes.delete) {
            actions.push({
                id: 'delete',
                label: 'Delete',
                isVisible: (id) => this.deletable(id),
            });
        }

        if (this.props.permissions.publish.toolbox) {
            actions.push({
                id: 'publish',
                label: 'Publish',
                isVisible: (id) => !this.isPublished(id),
            });
        }

        if (this.props.roles.includes('ADMIN')) {
            actions.push({
                id: 'publish',
                label: 'Publish',
                isVisible: (id) => this.isPublishableForAdmin(id),
            });
        }

        if (this.props.permissions.publish.toolbox) {
            actions.push({
                id: 'retract',
                label: 'Pull Back',
                isVisible: (id) => this.isPublished(id),
            });
        }

        if (this.props.roles.includes('ADMIN')) {
            actions.push({
                id: 'retract',
                label: 'Pull Back',
                isVisible: (id) => this.isPublishedAndAdminToolbox(id),
            });
        }

        this.setState({ actions });

        // try to get current page and rowsPerPage from url

    }

    setPage = (page) => {
        console.log("INFO: ToolboxOverview page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ToolboxOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }

    handleAction = (event, action) => {
        if (action.id === 'edit') {
            this.props.editToolbox(action.rowId);
        } else if (action.id === 'delete') {
            if (action.rowId) {
                this.setState({ toolboxToDelete: action.rowId });
                this.showConfirmDialog();
            }

            //this.props.deleteToolbox(action.rowId);
        } else if (action.id === 'deploy') {
            const toolbox = this.props.toolboxes.find(
                (t) => t.id === action.rowId
            );
            if (toolbox) {
                this.props.deployToolbox(toolbox.id)
            }
        } else if (action.id === 'publish') {
            const toolbox = this.props.toolboxes.find(
                (t) => t.id === action.rowId
            );
            if (toolbox) {
                this.props.publishToolbox(toolbox.id, (success: boolean, errorMessage: string) => {
                    if (success) {
                        this.props.showSnackbar(`${i18n.t('Toolbox')} "${toolbox.title}" ${i18n.t('Version')} ${toolbox.version} ${i18n.t('is published')}`);
                    } else {
                        switch (errorMessage) {
                            case "TOOLBOX_QUESTION_MIN_1_QUESTION":
                                this.showToolboxQuestionCountError()
                                break;
                            case "TOOLBOX_QUESTION_MIN_2_ANSWERS":
                                this.showToolboxQuestionAnswerError()
                                break;
                            case "TOOLBOX_QUESTION_MIN_1_CORRECT_ANSWER":
                                this.showToolboxQuestionCorrectAnswerError();
                                break;
                            // no default
                        }
                    }
                })
            }
        } else if (action.id === 'retract') {
            this.props.retractToolbox(action.rowId);
        }
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    checkValidToolboxAndPerformAction = (toolbox, action: () => mixed) => {
        // Check eerst of er wel vragen zijn
        if (!toolbox.toolbox.questions.length) {
            this.showToolboxQuestionCountError();
        } else {
            // Check of alle vragen ook mogelijke antwoorden hebben
            let questionsWithoutAnswers = toolbox.toolbox.questions
                .map((q, i) => {
                    return { index: i, answers: q.possibleAnswers };
                })
                .filter((q) => q.answers.length < 2)
                .map((q) => q.index + 1);

            if (questionsWithoutAnswers.length) {
                this.showToolboxQuestionAnswerError(questionsWithoutAnswers);
            } else {
                let questionsWithoutCorrectAnswers = toolbox.toolbox.questions
                    .map((q, i) => {
                        return { index: i, correct: q.correctAnswers };
                    })
                    .filter((q) => !q.correct.length)
                    .map((q) => q.index + 1);

                if (questionsWithoutCorrectAnswers.length) {
                    this.showToolboxQuestionCorrectAnswerError(
                        questionsWithoutCorrectAnswers
                    );
                } else {
                    action();
                }
            }
        }
    };

    showToolboxQuestionCountError = () => {
        const { t } = this.props;
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText: t(
                'Toolbox must have at least one question'
            ),
        });
    };

    showToolboxQuestionAnswerError = () => {
        const { t } = this.props;
        let text =
            t('All questions in the toolbox must have at least two possible answers.');
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText: text,
        });
    };

    publishedToText = (published) => {
        let text = '';
        let colorValue = 0;

        switch (published) {
            case 'PUBLISHED':
                text = 'JA';
                colorValue = 1;
                break;
            case 'UNPUBLISHED':
                text = 'CONCEPT';
                break;
            case 'RETRACTED':
                text = 'CONCEPT';
                colorValue = 0.5;
                break;
            default:
                text = '-';
                break;
        }

        return (
            <div
                className={'border-text'}
                style={{
                    backgroundColor: getColorForPercentage(colorValue),
                    borderColor: getColorForPercentage(colorValue),
                    width: '78px',
                }}
            >
                {text}
            </div>
        );
    };

    showToolboxQuestionCorrectAnswerError = () => {
        const { t } = this.props;
        let text = t('All questions in the toolbox must have an answer marked as correct.');
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText: text,
        });
    };

    handleAddButtonClick = (event) => {
        this.props.createToolbox();
    };

    mapToolboxToRow = (toolbox) => {
        const { t } = this.props;
        let sectors = '-';
        let height = 0;

        if (this.props.sectorEntities && this.props.sectorEntities.byId) {
            if (
                toolbox.sectors.length === 1 &&
                this.props.sectorEntities.byId[toolbox.sectors[0]]
            ) {
                sectors = this.props.sectorEntities.byId[toolbox.sectors[0]]
                    .name;
            } else if (toolbox.sectors.length > 1) {
                height = height + toolbox.sectors.length * 10;
                sectors = (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{
                                width: '100px',
                                height: height,
                                left: '-65px',
                            }}
                        >
                            {toolbox.sectors.map((id, index) => (
                                <div key={index}>
                                    {(this.props.sectorEntities.byId[id] &&
                                        this.props.sectorEntities.byId[id]
                                            .name) ||
                                        ''}
                                </div>
                            ))}
                        </span>
                        <div>
                            {toolbox.sectors.length} {t('Sectors')}
                        </div>
                    </div>
                );
            }
        }

        moment.locale(i18n.t('LOCALE'));


        //hier

        return {
            ...toolbox,
            sectors: sectors,
            category:
                toolbox.categoryId &&
                this.props.categoryEntities.byId[toolbox.categoryId]
                    ? t(
                          this.props.categoryEntities.byId[toolbox.categoryId]
                              .locKey ||
                              this.props.categoryEntities.byId[toolbox.categoryId]
                                  .name
                      )
                    : '-',
            date: !toolbox.date
                ? '-'
                : moment(toolbox.date).format(t('dateTime')),
            deployed: !toolbox.lastDeployed
                ? '-'
                : moment(toolbox.lastDeployed).format(t('dateTime')),
            published: this.publishedToText(toolbox.published),
            logo:
                toolbox.source === 'cumela' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '30px' }}
                        >
                            {t('Cumela toolbox')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/cumela-logo.png"
                            alt={t('Cumela logo')}
                        />
                    </div>
                ) : toolbox.source === 'global' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '10px' }}
                        >
                            {t('Veilig Vakwerk toolbox')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-admin-logo.svg"
                            alt={t('Admin logo')}
                        />
                    </div>
                ) : toolbox.rootboxSource === 'global' ||
                  toolbox.rootboxSource === 'cumela' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '15px' }}
                        >
                            {t('Custom toolbox')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/aangepast.svg"
                            alt={t('Custom logo')}
                        />
                    </div>
                ) : (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '30px' }}
                        >
                            {t('Own toolbox')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-logo.svg"
                            alt={t('Own logo')}
                        />
                    </div>
                ),
        };
    };

    onRowClick = (row, event) => {
        if (row.id) {
            // set Crumb for this tab and page
            this.props.pushCrumbPath({crumb: { name: i18n.t('Toolboxen'), link: `/toolboxen#overzicht?page=${this.state.page}&rows=${this.state.rowsPerPage}`}});
            this.props.goToDetailView(row.id, undefined ,this.state.page, this.state.rowsPerPage);
        }
    };

    toolboxSorter = () => {
        return {
            sectors: null,
            deployed: (a, b) => {
                a = a.lastDeployed;
                b = b.lastDeployed;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            title: (a, b) => {
                a = a.title.toLowerCase();
                b = b.title.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            logo: (a, b) => {
                if (a.source > b.source) {
                    return 1;
                } else if (a.source < b.source) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    deletable = (toolboxId) => {
        const toolbox = this.props.toolboxes.find(
            (toolbox) => toolbox.id === toolboxId
        );

        return (
            (toolbox.source === 'global' &&
                this.props.roles.includes('ADMIN')) ||
            (toolbox.source === 'cumela' &&
                this.props.roles.includes('CUMELA')) ||
            (toolbox.source === 'Janssen de Jong Groep' &&
                this.props.roles.includes('ROLE_JANSSENDEJONG')) ||
            (toolbox.source !== 'global' && toolbox.source !== 'cumela')
        );
    };

    isPublished = (toolboxId) => {
        const toolbox = this.props.toolboxes.find(
            (toolbox) => toolbox.id === toolboxId
        );

        if (!toolbox) {
            return false;
        }

        return toolbox.published === 'PUBLISHED';
    };

    isPublishedAndAdminToolbox = (toolboxId) => {
        const toolbox = this.props.toolboxes.find(
            (toolbox) => toolbox.id === toolboxId
        );

        if (!toolbox) {
            return false;
        }

        if (toolbox.source === 'global') {
            return toolbox.published === 'PUBLISHED';
        }
        return false;
    };

    isPublishableForAdmin = (toolboxId) => {
        const toolbox = this.props.toolboxes.find(
            (toolbox) => toolbox.id === toolboxId
        );

        if (!toolbox) {
            return false;
        }

        if (
            toolbox.source === 'global' &&
            (toolbox.published === 'UNPUBLISHED' ||
                toolbox.published === 'RETRACTED')
        ) {
            return true;
        }
        return false;
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openType: false,
            openSector: false,
            openCategory: false,
        });
        this.setState(newState);
    };

    handleTypeFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({ typeFilter: event.target.value });
    };

    handleTouchTap2 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openSector: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getFilterSectorValue(sectorfilter) {
        const { t } = this.props;
        let filteredSector = t('Everything');
        if (sectorfilter === 0) {
            return filteredSector;
        } else if (sectorfilter === -1) {
            return 'Geen sector';
        }

        this.props.sectors.forEach((sector) => {
            if (sector.id === sectorfilter) {
                filteredSector = sector.name;
            }
        });

        return filteredSector;
    }

    handleToolboxCategoryFilterChange = (event) => {
        this.handleRequestClose();

        let cat = 'all';
        if (event.target.value === -1) {
            cat = 'all';
        } else {
            cat = categories[event.target.value].id;
        }

        this.setState({ categoryFilter: cat });
    };

    handleToolboxSectorFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({ sectorFilter: event.target.value });
    };

    getFilterCategoryValue(catfilter) {
        const { t } = this.props;
        let filteredCat = t('Everything');
        if (catfilter === 'all') {
            return filteredCat;
        }

        this.props.categories.forEach((cat) => {
            if (cat.id === catfilter) {
                filteredCat = cat.name;
            }
        });

        return filteredCat;
    }

    handleTouchTap3 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openCategory: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getFilterBar = (toolboxes, searchresults) => {
        const { t } = this.props;
        let sectorIds = [];
        let sectors = [];

        let catIds = [];
        categories = [];

        let vvw = false;
        let cumela = false;
        let aangepast = false;
        let eigen = false;

        this.props.toolboxes.forEach((inspection) => {
            if (
                inspection.source !== 'Janssen de Jong Groep' &&
                inspection.source !== 'cumela' &&
                inspection.source !== 'global' &&
                inspection.rootboxSource !== 'cumela' &&
                inspection.rootboxSource !== 'global' &&
                inspection.rootboxSource !== 'Janssen de Jong Groep'
            ) {
                eigen = true;
            } else if (
                inspection.source !== 'Janssen de Jong Groep' &&
                inspection.source !== 'cumela' &&
                inspection.source !== 'global' &&
                (inspection.rootboxSource === 'cumela' ||
                    inspection.rootboxSource === 'global' ||
                    inspection.rootboxSource === 'Janssen de Jong Groep')
            ) {
                aangepast = true;
            } else if (inspection.source === 'global') {
                vvw = true;
            } else if (inspection.source === 'cumela') {
                cumela = true;
            }
            if (inspection.sectors.length > 0) {
                inspection.sectors.forEach((sector) => {
                    if (!sectorIds.includes(sector)) {
                        sectorIds.push(sector);
                    }
                });
            }
            if (inspection.categoryId.length > 0) {
                if (!catIds.includes(inspection.categoryId)) {
                    catIds.push(inspection.categoryId);
                }
            }
        });

        sectorIds.forEach((sector) => {
            this.props.sectors.forEach((sec) => {
                if (sector === sec.id) {
                    sectors.push(sec);
                }
            });
        });

        catIds.forEach((cat) => {
            this.props.categories.forEach((cat2) => {
                if (cat === cat2.id) {
                    categories.push(cat2);
                }
            });
        });

        let catIndex = 0;
        return (
            <div className="filterbar">
                <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    {toolboxes.length +
                        ' ' +
                        t('Toolbox') +
                        (toolboxes.length === 1 ? '' : t('and')) +
                        (this.state.searchtext.length > 0
                            ? (searchresults.length === 1
                                  ? ' - ' +
                                    searchresults.length +
                                    ' ' +
                                    t('searchresult')
                                  : '') +
                              (searchresults.length > 1 ||
                              searchresults.length === 0
                                  ? ' - ' +
                                    searchresults.length +
                                    ' ' +
                                    t('searchresults')
                                  : '')
                            : '')}
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={'Type'}
                            filterValue={
                                this.state.typeFilter === 0
                                    ? t('Everything')
                                    : this.state.typeFilter === 1
                                    ? t('Veilig Vakwerk')
                                    : this.state.typeFilter === 2
                                    ? t('CUMELA')
                                    : this.state.typeFilter === 3
                                    ? t('Edited')
                                    : t('Own')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openType : false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0}>
                        {t('Everything')}
                    </MenuItem>
                    {vvw && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={1}
                        >
                            {t('Veilig vakwerk')}
                        </MenuItem>
                    )}
                    {cumela && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={2}
                        >
                            {t('CUMELA')}
                        </MenuItem>
                    )}
                    {aangepast && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={3}
                        >
                            {t('Edited')}
                        </MenuItem>
                    )}
                    {eigen && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={4}
                        >
                            {t('Own')}
                        </MenuItem>
                    )}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap2}>
                        <FilterHeader
                            filterTitle={t('Sector')}
                            filterValue={this.getFilterSectorValue(
                                this.state.sectorFilter
                            )}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openSector : false}
                    onClose={this.handleRequestClose}
                    value={
                        this.state.sectorFilter ? this.state.sectorFilter : 0
                    }
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleToolboxSectorFilterChange}
                        value={0}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {sectors.map((sector) => {
                        return (
                            <MenuItem
                                key={sector.id}
                                onClick={this.handleToolboxSectorFilterChange}
                                value={sector.id}
                            >
                                {t(sector.name)}
                            </MenuItem>
                        );
                    })}
                    <MenuItem
                        onClick={this.handleToolboxSectorFilterChange}
                        value={-1}
                    >
                        {t('No sector')}
                    </MenuItem>
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap3}>
                        <FilterHeader
                            filterTitle={t('Category')}
                            filterValue={this.getFilterCategoryValue(
                                this.state.categoryFilter
                            )}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openCategory : false}
                    onClose={this.handleRequestClose}
                    value={this.state.categoryFilter}
                    onChange={this.handleToolboxCategoryFilterChange}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleToolboxCategoryFilterChange}
                        value={-1}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {categories.map((cat) => {
                        return (
                            <MenuItem
                                key={cat.id}
                                onClick={this.handleToolboxCategoryFilterChange}
                                value={catIndex++}
                            >
                                {t(cat.locKey || cat.name)}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    };

    render() {
        const { loading, t } = this.props;
        const {
            actions,
            columns,
            toolboxErrorDialogText,
            toolboxErrorDialogOpen,
        } = this.state;

        let toolboxes = this.props.toolboxes;

        toolboxes = toolboxes.filter((tb) => {
            return !tb.deleted;
        });
        const searchresults = toolboxes
            .filter((toolbox) => {
                let sectorFound = false;
                if (
                    this.props.sectorEntities &&
                    this.props.sectorEntities.byId
                ) {
                    toolbox.sectors.forEach((sector) => {
                        const sect = this.props.sectorEntities.byId[sector];

                        if (sect) {
                            if (
                                sect.name
                                    .toLowerCase()
                                    .indexOf(
                                        this.state.searchtext.toLowerCase()
                                    ) > -1
                            ) {
                                sectorFound = true;
                            }
                        }
                    });
                }

                let textFound = false;
                /*
            NOTE: Excluded blocks from the projection
            toolbox.toolbox.blocks.map(block => {
                if (block.title.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1 || (block.textBlock && block.textBlock.text.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1)){
                    textFound = true;
                }
                if (block.listBlock){
                    block.listBlock.items.map(item => {
                        if (item.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1){
                            textFound = true;
                        }
                    })
                }
            });

            toolbox.toolbox.questions.map(block => {
                if (block.title.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1){
                    textFound = true;
                }
            });
            */

                return (
                    sectorFound ||
                    textFound ||
                    toolbox.title
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1 ||
                    (toolbox.description &&
                        toolbox.description
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                            -1) ||
                    (toolbox.author &&
                        toolbox.author
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) >
                            -1) ||
                    (toolbox.categoryId &&
                        toolbox.categoryId
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) > -1)
                );
            })
            .filter((inspection) => {
                if (this.state.typeFilter === 0) {
                    return true;
                } else if (this.state.typeFilter === 1) {
                    if (inspection.source === 'global') {
                        return true;
                    }
                } else if (this.state.typeFilter === 2) {
                    if (inspection.source === 'cumela') {
                        return true;
                    }
                } else if (this.state.typeFilter === 3) {
                    if (
                        inspection.source !== 'cumela' &&
                        inspection.source !== 'global' &&
                        (inspection.rootboxSource === 'cumela' ||
                            inspection.rootboxSource === 'global')
                    ) {
                        return true;
                    }
                } else if (this.state.typeFilter === 4) {
                    if (
                        inspection.source !== 'cumela' &&
                        inspection.source !== 'global' &&
                        inspection.rootboxSource !== 'cumela' &&
                        inspection.rootboxSource !== 'global'
                    ) {
                        return true;
                    }
                }
                return false;
            })
            .filter((toolbox) =>
                this.state.sectorFilter && this.state.sectorFilter !== 0
                    ? this.state.sectorFilter !== -1
                        ? toolbox.sectors.includes(this.state.sectorFilter)
                        : toolbox.sectors.length === 0
                    : true
            )
            .filter((toolbox) =>
                this.state.categoryFilter && this.state.categoryFilter !== 'all'
                    ? toolbox.categoryId === this.state.categoryFilter
                    : true
            );

        const tableActionHeader = (
            <TableActionHeader
                onSearchChange={this.handleSearch}
                searchPlaceholder={t('Search within toolboxes')}
                title={this.getFilterBar(toolboxes, searchresults)}
                widemode={true}
            />
        );

        return (
            <div>
                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    <Dialog
                        open={toolboxErrorDialogOpen}
                        onClose={() => {
                            this.setState({ toolboxErrorDialogOpen: false });
                        }}
                    >
                        <DialogTitle>
                            {t('Unable to publish toolbox')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {toolboxErrorDialogText}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        toolboxErrorDialogOpen: false,
                                    });
                                }}
                                 
                            >
                                {t('Ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <ConfirmDialog
                        open={this.state.showConfirmDialog}
                        handleClose={this.hideConfirmDialog}
                        deletetoolbox={this.state.toolboxToDelete}
                        deleteToolboxFunction={this.props.deleteToolbox}
                    />

                    <div
                        className={classnames({
                            [classes.button]: true,
                            'mui-fixed': true,
                        })}
                    >
                        <div className={'tooltip'}>
                            <span
                                className={'tooltiptext'}
                                style={{ top: '60px' }}
                            >
                                {t('New toolbox')}
                            </span>
                            <Fab
                                 
                                onClick={this.handleAddButtonClick}
                            >
                                <AddIcon />
                            </Fab>
                        </div>
                    </div>
                    <EnhancedTable
                        hover
                        defaultOrderBy={
                            this.props.roles.includes('CUMELA')
                                ? 'date'
                                : 'deployed'
                        }
                        defaultOrder="asc"
                        columns={columns}
                        onClick={this.onRowClick}
                        rows={searchresults}
                        formatter={this.mapToolboxToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.toolboxSorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t(
                                      'No toolboxes have been added yet, add a toolbox by clicking the plus sign at the top right.'
                                  )
                        }

                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}

                    />
                </AppModule>
            </div>
        );
    }
}

var percentColors = percentColors1;

var getColorForPercentage = function (pct) {
    let color = percentColors[0];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};


export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(ToolboxOverview))
);
