// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Typography  } from '@mui/material';
import type { TBreadCrumb } from '../../reducers/uiReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from  './BreadCrumbs.module.scss'
import {popCrumbPath} from "../../actions";

type Props = {
    goto: (url: string) => void,
    breadCrumb: TBreadCrumb,
};

type State = {};

const mapDispatchToProps = (dispatch) => {
    return {
        popCrumbPath: () => {
            dispatch(popCrumbPath());
        },
    };
};


class BreadCrumbs extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    handleClick = (index, link) => {
        let numberOfCrumbs = this.props.breadCrumb.crumbs ? this.props.breadCrumb.crumbs.length : 0;

        for (let i = index+1; i <= numberOfCrumbs; i++) {
            this.props.popCrumbPath();
        }

        this.props.goto(link);
    }

    render() {
        const { breadCrumb, t } = this.props;
        return (

            <div className={classes.root}>
                {breadCrumb.title && (
                    <Typography className={classes.crumb} variant="h2">
                        {t(breadCrumb.title)}
                    </Typography>
                )}

                {breadCrumb.crumbs &&
                    breadCrumb.crumbs.map((crumb, index) => {
                        return (
                            <Typography
                                type="body1"
                                key={index}
                            >
                                {crumb.link && (
                                    <span className={classes.crumbSlash}>{"/"}</span>
                                )}
                                <span className={classes.crumbText}  onClick={() => this.handleClick(index, crumb.link)}>
                                    {crumb.name}
                                </span>
                            </Typography>
                        );
                    })}
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect((store) => ({ breadCrumb: store.ui.breadCrumb }), mapDispatchToProps),
)(BreadCrumbs);
