// @flow

import { connect } from 'react-redux';
import React from 'react';
import './EmployeeEditView.css';
import EmployeeEditCard from '../EmployeeEditCard';
import { bindActionCreators } from 'redux';
import { fetchEmployees, editEmployee, upsertEmployee, setCrumbPath } from '../../../actions';
import { push, goBack } from 'connected-react-router';

import type { TEmployee } from '../../../reducers/employeesReducer';

type MyProps = {
    match: {
        params: {
            employeeid: string
        }
    },
    location: {
        query: {
            employee?: string
        }
    }
};

type Props = {
    editEmployee: (employee?: TEmployee) => mixed,
    onEdit: (employee?: TEmployee) => mixed,
    loadEmployees: () => mixed,
    upsertEmployee: (employee: TEmployee) => mixed,
    goBack: () => mixed,
    editemployee: TEmployee,
    employeeid: string,
    employeesbyid: Map<number, TEmployee>
};

type State = {};

const mapStateToProps = (state, props: MyProps) => {
    return {
        editemployee: state.drafts.employees.editemployee,
        employeeid: props.match.params.employeeid,
        employeesbyid: state.entities.employees.byId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        editEmployee: bindActionCreators(editEmployee, dispatch),
        loadEmployees: bindActionCreators(fetchEmployees, dispatch),
        navigate: newPath => {
            dispatch(push(newPath));
        },
        goBack: () => {
            dispatch(goBack());
        },
        onSave: employee => {
            upsertEmployee(employee)(dispatch);
            dispatch(push('/medewerkers'));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Medewerkers', crumbs: [{ name: 'aanmaken' }] }));
        }
    };
};

class EmployeeEditView extends React.PureComponent<Props, State> {
    state: State = {};

    componentDidMount() {
        this.processProps(this.props);
        this.props.loadEmployees();

        this.props.setCrumbPath();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.processProps(this.props)
        }
    }

    cleanEditEmployee = (props: Props) => {
        props.editEmployee({
            username: '',
            firstname: '',
            middlename: '',
            lastname: '',
            phonenumber: '',
            importType: 'MANUAL',
            state: 'ADDED',
            roles: ['EMPLOYEE']
        });
    };

    processProps = (props: Props) => {
        const paramId = props.employeeid ? parseInt(props.employeeid, 10) : undefined;

        if (!props.editemployee && props.employeeid === undefined) {
            this.cleanEditEmployee(props);
        } else if (!props.editemployee || props.editemployee.id !== paramId) {
            props.editEmployee(props.employeesbyid.get(parseInt(props.employeeid, 10)));
        }
    };

    render() {
        return (
            <div className="EmployeeEditView">
                <EmployeeEditCard employee={this.props.editemployee} onEdit={this.props.editEmployee} onSave={this.props.onSave} goBack={this.props.goBack} />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEditView);
