// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';

import {Fab} from '@mui/material';

import ToolboxDetail from '../../ToolboxDetail';
import ToolboxAssignInformation from '../../ToolboxAssignInformation';
import ToolboxPreview from '../../ToolboxPreview';
import _ from 'lodash';
import {
    getToolboxByIdV2,
    getToolboxResultsOverviewForDeployment,
    initToolboxDraft, setCrumbPath,
    showSnackbar,
} from '../../../actions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import AppModule from '../../AppModule/AppModule';
import {fetchPDF} from '../../../lib/api';
import classnames from 'classnames';
import DescIcon from '@mui/icons-material/Description';
import {isUndefinedOrNull} from '../../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {V2_TOOLBOX_ASSIGN_RESULT_OVERVIEW_REDUX} from "../../../lib/apiTypes";
import ToolboxTrendView from "../ToolboxTrend/ToolboxTrendView";
import classes from './ToolboxDetailView.module.scss';
import colors from "../../../lib/themes/theme.scss";
import i18n from "../../../i18n";

type Props = {
    match: any,
    toolbox?: any,
    toolboxResultsOverview: any,
    fetchSectors: () => mixed,
    fetchCategories: () => mixed,
    getToolboxById: () => mixed,
    getToolboxResultsOverviewForDeployment: (toolboxId: string, deploymentId: string) => mixed,
    initDraft: (toolbox: any) => mixed,
    setCrumbPath: () => void,
    showSnackbar: (text: string) => void,
    deploymentId: number
};

type State = {
    tab: number,
};

const mapStateToProps = (store, props) => {
    const toolboxId = props.match.params.toolboxId;
    let deploymentId = props.match.params.deploymentId

    // Get toolbox
    const toolboxDraft = store.drafts.toolboxes[toolboxId];
    let toolbox = store.entities.toolbox.byId[toolboxId];

    // Get results
    let resultEntities = store.entities[V2_TOOLBOX_ASSIGN_RESULT_OVERVIEW_REDUX];
    let toolboxResultsOverview = [];
    if (!isUndefinedOrNull(deploymentId) && resultEntities.byId[deploymentId]) {
        toolboxResultsOverview = resultEntities.byId[deploymentId].results || []
    }

    return {
        toolbox: toolbox,
        toolboxDraft: toolboxDraft,
        toolboxResultsOverview: toolboxResultsOverview,
        contentBlockDrafts: store.drafts.contentBlocks,
        allSectors: store.entities.sectors,
        allCategories: store.entities.categories,
        deploymentId: props.match.params.deploymentId
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initDraft: (toolbox) => {
            dispatch(initToolboxDraft(toolbox));
        },
        getToolboxById: (toolboxId) => {
            dispatch(getToolboxByIdV2(toolboxId));
        },
        getToolboxResultsOverviewForDeployment: (toolboxId, deploymentId) => {
            dispatch(getToolboxResultsOverviewForDeployment(toolboxId, deploymentId));
        },
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
        setCrumbPath: (crumbs) => {
            dispatch(setCrumbPath(crumbs));
        }
    };
};


class ToolboxDetailView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };

    // componentWillReceiveProps(nextProps) {
    //     if (
    //         nextProps.toolbox &&
    //         _.get(this, 'props.toolbox.id', null) !==
    //         _.get(nextProps, 'toolbox.id', null) &&
    //         _.get('props.toolbox.toolbox', null) !== null &&
    //         _.get('props.toolbox.user', null) !== null &&
    //         !isUndefinedOrNull(this.props.toolbox) &&
    //         !isUndefinedOrNull(this.props.toolbox.toolbox)
    //     ) {
    //         this.props.initDraft(nextProps.toolbox);
    //     }
    // }


    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        // The toolbox has been retrieved, let's set the draft and crumb
        const { toolboxDraft = {}, toolbox } = this.props;

        if (
            !isUndefinedOrNull(toolbox) &&
            ((toolbox.id && toolboxDraft.id !== toolbox.id) ||
                (!_.isEqual(
                    prevProps.toolbox ? prevProps.toolbox.toolbox : null,
                    this.props.toolbox.toolbox
                ) &&
                    !isUndefinedOrNull(this.props.toolbox.toolbox)))
        ) {
            this.props.initDraft(this.props.toolbox);
        }
    }

    handleTabChange = (event: any, value: number, href: any) => {
        this.setState({ tab: value });
    };

    componentDidMount() {
        const tabs = ['#voorbeeld', '#informatie', '#resultaten'];

        if (this.props.location.hash) {
            const hash = this.props.location.hash.split("?")[0];

            let index = tabs.indexOf(hash);
            if (index === -1) {
                index = 0;
            }

            this.setState({
                tab: index,
            });
        }
        console.log("INFO: componentDidMount try to set page and rowsPerPage");
        if (this.props.location.search.includes("page")) {
            const fromPage = new URLSearchParams(this.props.location.search).get("page");

            this.setState({ page: Number(fromPage) });
        }
        if (this.props.location.search.includes("rowsPerPage")) {
            const rowsPerPage = new URLSearchParams(this.props.location.search).get("rowsPerPage");
            this.setState({ rowsPerPage: Number(rowsPerPage) });
        }

        const { match, getToolboxById, getToolboxResultsOverviewForDeployment } = this.props;

        if (match.params.toolboxId) {
            getToolboxById(match.params.toolboxId);
        }

        if (match.params.deploymentId) {
            getToolboxResultsOverviewForDeployment(match.params.toolboxId, match.params.deploymentId);
        }

        if (
            this.props.toolbox &&
            this.props.toolbox.toolbox &&
            this.props.toolbox.user &&
            !isUndefinedOrNull(this.props.toolbox.toolbox)
        ) {
            this.props.initDraft(this.props.toolbox);
        }

        if (this.props.history && this.props.history.action === 'POP') {
            // Page was refreshed / Reloaded history has been erased
            this.props.setCrumbPath({ title: 'Toolboxen', crumbs: [ { name: i18n.t('Toolboxen'), link: '/toolboxen#overzicht' } ] });
        }

    }

    getToolboxResultPDF = (toolbox, toolboxResults) => {
        const data = [
            toolbox,
            toolboxResults
        ];
        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOX_RESULT_V2_RAPPORTAGE',
            options: {},
            data: data,
            info: {
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek,
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    'toolboxRapportage_' + toolbox.title +  '.pdf'
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    }

    handlePDFClickNoResults = () => {
        const { toolbox, t } = this.props;

        let pdfToolbox = Object.assign({}, toolbox);
        if (
            isUndefinedOrNull(pdfToolbox.sectors) &&
            isUndefinedOrNull(pdfToolbox.category)
        ) {
            showSnackbar(t('Toolbox is loading, please wait.'));
            return;
        }

        if (!isUndefinedOrNull(pdfToolbox.sectors))
            pdfToolbox.sectors = pdfToolbox.sectors.map((id, index) => {
                return this.props.allSectors.byId[id]
                    ? t(
                          this.props.allSectors.byId[id].locKey ||
                              this.props.allSectors.byId[id].name
                      )
                    : id;
            });
        if (!isUndefinedOrNull(pdfToolbox.category))
            pdfToolbox.category = this.props.allCategories.byId[
                pdfToolbox.category
            ]
                ? t(
                      this.props.allCategories.byId[pdfToolbox.category]
                          .locKey ||
                          this.props.allCategories.byId[pdfToolbox.category]
                              .name
                  )
                : pdfToolbox.category;

        pdfToolbox.showResults = false;

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'TOOLBOX',
            options: {},
            data: [],
            info: { toolbox: pdfToolbox },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    this.props.toolbox.title +
                        ' v' +
                        this.props.toolbox.version +
                        '.pdf'
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    render() {
        const {
            toolbox,
            contentBlockDrafts,
            toolboxDraft,
            toolboxResultsOverview,
            allSectors,
            allCategories,
            deploymentId,
            t,
        } = this.props;
        const { tab } = this.state;

        let toolboxTitle = toolbox ? `Toolbox: ${toolbox.title}` : '-';
        const tableActionHeader = <TableActionHeader title={toolboxTitle} />;
        const deployedToolboxResultsOverview = (toolboxResultsOverview || []).filter((ta) => ta.deployed);

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{ '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab value={Number(0)} label={t('Example')} href="#voorbeeld" sx={{ color: colors.mainTabsColor }}/>
                    <Tab value={Number(1)} label={t('Information')} href="#informatie" sx={{ color: colors.mainTabsColor }}/>
                    {deployedToolboxResultsOverview && deployedToolboxResultsOverview.length && deploymentId && (
                        <Tab value={Number(2)} label={t('Results')} href="#resultaten" sx={{ color: colors.mainTabsColor }}/>
                    )}
                    <Tab value={Number(3)} label={t('Trend')} href="#analyse" sx={{ color: colors.mainTabsColor }}/>
                </Tabs>
                <AppModule hasTabs prepend={tableActionHeader}>
                    {tab === 0 && toolboxDraft && contentBlockDrafts && (
                        <ToolboxPreview
                            toolbox={toolboxDraft}
                            onPDFClick={this.handlePDFClickNoResults}
                        />
                    )}
                    {tab === 1 && toolbox && (
                        <ToolboxAssignInformation
                            deploymentId={deploymentId}
                            toolboxResultsOverview={toolboxResultsOverview}
                            toolbox={toolbox}
                            allSectors={allSectors}
                            allCategories={allCategories}
                        />
                    )}
                    {tab === 2 &&
                        deployedToolboxResultsOverview &&
                        deployedToolboxResultsOverview.length &&
                        deploymentId && (
                            <ToolboxDetail
                                toolboxResultsOverview={deployedToolboxResultsOverview}
                                toolbox={toolbox}
                                deploymentId={deploymentId}
                            />
                        )}

                    {tab === 2 && (
                        <div
                            className={classnames({
                                [classes.button]: true,
                                'mui-fixed': true,
                            })}
                        >
                            <div className={'tooltip'}>
                                <span
                                    className={'tooltiptext'}
                                    style={{ top: '60px' }}
                                >
                                    {t('Download toolbox as PDF')}
                                </span>
                                <Fab
                                    onClick={() => this.getToolboxResultPDF(toolbox, deployedToolboxResultsOverview)}
                                >
                                    <DescIcon />
                                </Fab>
                            </div>
                        </div>
                    )}
                    {tab === 3 && (
                        <ToolboxTrendView
                            toolbox={toolbox}
                        />
                    )}
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(ToolboxDetailView);
