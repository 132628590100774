// @flow

import { connect } from 'react-redux';
import React from 'react';
import InspectionDetailView from '../../Inspection/InspectionDetailView';

type Props = {};

const mapStateToProps = (state, props) => {
    const inspectionId = props.match.params.inspectionId;
    const inspectionAssign = state.entities.inspectionAssigns.byId[inspectionId];
    return { inspectionAssign, inspection: inspectionAssign ? inspectionAssign.inspection : null };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

class StatisticsInspectionDetailView extends React.Component<Props, void> {
    props: Props;

    render() {
        return <InspectionDetailView {...this.props} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsInspectionDetailView);
