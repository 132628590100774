// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';


import _ from 'lodash';
import {
    getToolboxAssignById,
    getToolboxById,
    setCrumbPath,
} from '../../../actions';
import { getAssignedToolboxes } from '../../../selectors';

import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import AppModule from '../../AppModule/AppModule';
import TitleDescription from '../../TitleDescription';
import moment from '../../../lib/moment';
import { isUndefinedOrNull, percentColors1 } from '../../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import classes from './ToolboxResultsViewStats.module.scss'


/**
 * !!!!!!!!!
 *
 *
 * THIS IS A TEMPORARY COPY OF [ToolboxResultsView] TO DO AN EARLY
 * RELEASE WITHOUT HAVING TO REFACTOR THE WHOLE STATISTICS SECTION
 *
 *
 *
 * !!!!!!!!!
 */



type Props = {
    match: any,
    toolbox?: any,
    fetchToolboxes: () => mixed,
    fetchToolboxAssigns: () => mixed,
    getToolboxById: () => mixed,
    getToolboxAssignById: () => mixed,
    setCrumbPath: (toolboxName: string) => void,
};

const mapStateToProps = (store, props) => {
    const toolboxes = store.entities.toolboxes;
    const toolboxId = props.match.params.toolboxId;
    const toolboxDate = props.match.params.toolboxDate;

    let toolbox = toolboxes.byId[toolboxId];
    if (isUndefinedOrNull(toolbox) || isUndefinedOrNull(toolbox.toolbox)) {
        toolbox = store.entities.toolbox.byId[toolboxId];
    }

    let toolboxAssigns = getAssignedToolboxes(store);

    if (toolboxAssigns[0] != null) {
        toolboxAssigns = toolboxAssigns.filter(function (selection) {
            return selection.toolbox.id === toolboxId;
        });
    }

    toolboxAssigns = toolboxAssigns.filter(
        (box) =>
            moment(box.deployed).format('YYYY-MM-DD HH:mm') ===
            moment(toolboxDate).format('YYYY-MM-DD HH:mm')
    );

    const tb = toolbox
        ? toolbox
        : toolboxAssigns[0]
        ? toolboxAssigns[0].toolbox
        : null; //Prefer toolbox. If it is marked as removed we need to take the assign

    let employerId = props.match.params.employerId;
    let employee = store.entities.employees.byId[employerId];

    let myAssign;

    /*if (employee !== null && employee !== undefined){
        myAssign = toolboxAssigns.map(toolbox => toolbox.assignList.filter(assigns => assigns.user.id.toString() === employerId));
        myAssign = myAssign.filter(assign => assign !== null)
    }*/

    if (employerId && toolboxAssigns && toolboxAssigns.length !== 0) {
        myAssign = toolboxAssigns.map((toolbox) =>
            toolbox.assignList.filter(
                (assigns) => assigns.user.id.toString() === employerId
            )
        );
        myAssign = myAssign.filter((assign) => assign !== null);
    }

    if (
        props.match.path !==
        '/toolbox/details/:toolboxId/:toolboxDate/results/:employerId?'
    ) {
        if (store.entities.toolboxAssign.allIds.length > 0) {
            Object.keys(store.entities.toolboxAssign.byId).forEach((key) => {
                const entity =
                    store.entities.toolboxAssign.byId[key.toString()];
                if (
                    entity &&
                    entity.toolbox &&
                    entity.toolbox.id === toolboxId
                ) {
                    myAssign = [{ 0: entity }];
                }
            });
        }
    }

    return {
        toolbox: tb,
        toolboxAssigns: toolboxAssigns,
        employerId: employerId,
        employee: employee,
        myAssign: myAssign,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setCrumbPath:
            ownProps.setCrumbPath ||
            ((toolboxAssign, assign, assignId) => {
                const hash = new URLSearchParams(ownProps.location.search).get(
                    'from'
                );

                if (assign === undefined || assignId === undefined) {
                    dispatch(
                        setCrumbPath({
                            title: 'Toolboxen',
                            crumbs: [
                                {
                                    name: toolboxAssign.toolbox.title,
                                    link:
                                        '/toolboxen#' +
                                        (hash && hash.length
                                            ? hash
                                            : 'overzicht'),
                                },
                            ],
                        })
                    );
                    return;
                }

                if (hash === 'toolboxen') {
                    dispatch(
                        setCrumbPath({
                            title: 'Medewerkers',
                            crumbs: [
                                {
                                    name:
                                        this.props.employee.fullname ||
                                        this.props.employee.fullnameUser,
                                    link: '/medewerkers',
                                },
                                {
                                    name: toolboxAssign.toolbox.title,
                                    link:
                                        '/medewerker/details/' +
                                        this.props.employerId +
                                        '/#toolboxen',
                                },
                            ],
                        })
                    );
                } else if (hash === 'uitgestuurd') {
                    dispatch(
                        setCrumbPath({
                            title: 'Toolboxen',
                            crumbs: [
                                {
                                    name:
                                        i18n.t('Sent out') +
                                        ': ' +
                                        toolboxAssign.toolbox.title,
                                    link:
                                        '/toolboxen#' +
                                        (hash.length ? hash : 'overzicht'),
                                },
                                {
                                    name: assign.user.fullname,
                                    link:
                                        '/toolbox/details/' +
                                        toolboxAssign.toolbox.id +
                                        '/' +
                                        assignId +
                                        '?from=uitgestuurd#resultaten',
                                },
                            ],
                        })
                    );
                } else if (hash === 'overzicht') {
                    dispatch(
                        setCrumbPath({
                            title: 'Toolboxen',
                            crumbs: [
                                {
                                    name:
                                        'Toolboxen: ' +
                                        toolboxAssign.toolbox.title,
                                    link:
                                        '/toolboxen#' +
                                        (hash.length ? hash : 'overzicht'),
                                },
                                {
                                    name: moment(assign.deployed, '').format(
                                        'DD/MM/YYYY, HH:mm'
                                    ),
                                    link:
                                        '/toolbox/details/' +
                                        toolboxAssign.toolbox.id +
                                        '/' +
                                        toolboxAssign.toolbox.date +
                                        '?from=overzicht#resultaten',
                                },
                                {
                                    name: assign.user.fullname,
                                    link:
                                        '/toolbox/details/assign/' +
                                        assignId +
                                        '?from=overzicht',
                                },
                            ],
                        })
                    );
                } else if (hash === 'rapportages') {
                    dispatch(
                        setCrumbPath({
                            title: 'Rapportages',
                            crumbs: [
                                {
                                    name:
                                        'Toolbox: ' +
                                        toolboxAssign.toolbox.title,
                                    link: '/rapportages#toolboxen',
                                },
                                {
                                    name: assign.user.fullname,
                                    link:
                                        '/rapportages/toolbox/' +
                                        toolboxAssign.toolbox.id,
                                },
                            ],
                        })
                    );
                } else if (hash === 'rapportagesTM') {
                    dispatch(
                        setCrumbPath({
                            title: 'Rapportages',
                            crumbs: [
                                {
                                    name:
                                        'Toolbox: ' +
                                        toolboxAssign.toolbox.title,
                                    link: '/rapportages#toolboxen',
                                },
                                {
                                    name: 'Toolboxmeeting',
                                    link:
                                        '/rapportages/toolbox/' +
                                        toolboxAssign.toolbox.id,
                                },
                                {
                                    name: assign.user.fullname,
                                    link:
                                        '/toolbox/details/' +
                                        toolboxAssign.toolbox.id +
                                        '/' +
                                        assignId +
                                        '?from=rapportagesTM#resultaten',
                                },
                            ],
                        })
                    );
                } else if (hash === 'rapportagesTMgroups') {
                    dispatch(
                        setCrumbPath({
                            title: 'Rapportages',
                            crumbs: [
                                {
                                    name:
                                        'Toolbox: ' +
                                        toolboxAssign.toolbox.title,
                                    link: '/rapportages#toolboxen',
                                },
                                {
                                    name: 'Toolboxmeeting',
                                    link:
                                        '/rapportages/toolbox/' +
                                        toolboxAssign.toolbox.id,
                                },
                                {
                                    name: assign.user.fullname,
                                    link:
                                        '/rapportages/toolboxMeeting/' +
                                        assignId,
                                },
                            ],
                        })
                    );
                } else {
                    dispatch(
                        setCrumbPath({
                            title: 'Toolboxen',
                            crumbs: [
                                {
                                    name: toolboxAssign.toolbox.title,
                                    link:
                                        '/toolboxen#' +
                                        (hash && hash.length
                                            ? hash
                                            : 'overzicht'),
                                },
                            ],
                        })
                    );
                }
            }),
        getToolboxById: (toolboxId) => {
            dispatch(getToolboxById(toolboxId));
        },
        getToolboxAssignById: (toolboxAssignId) => {
            dispatch(getToolboxAssignById(toolboxAssignId));
        },
    };
};


class ToolboxResultsViewStats extends React.Component<Props, State> {
    props: Props;

    componentDidMount() {
        const { myAssign } = this.props;
        this.props.getToolboxById(this.props.match.params.toolboxId);

        if (Array.isArray(myAssign) && myAssign.length > 0 && myAssign[0][0]) {
            this.props.getToolboxAssignById(myAssign[0][0].id);
        }

        if (
            this.props.toolbox &&
            this.props.myAssign &&
            this.props.myAssign[0]
        ) {
            //Voor verwijderde gebruikers
            // this.props.setCrumbPath(
            //     { toolbox: this.props.toolbox },
            //     this.props.myAssign[0][0],
            //     this.props.toolboxAssigns[0].id
            // );
        }
    }


    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        const { myAssign } = this.props;
        if (
            prevProps.myAssign === undefined &&
            myAssign !== undefined &&
            Array.isArray(myAssign) &&
            myAssign.length > 0 &&
            myAssign[0][0]
        ) {
            this.props.getToolboxAssignById(myAssign[0][0].id);
        }
    }


    render() {
        const { toolbox, t } = this.props;
        const currentAssign =
            this.props.myAssign &&
            this.props.myAssign[0] &&
            this.props.myAssign[0][0];

        const tableActionHeader = (
            <TableActionHeader
                title={
                    this.props.employee
                        ? t('Results') + ' ' + this.props.employee.fullname
                        : currentAssign &&
                          t('Results') + ' ' + currentAssign.fullnameUser
                }
            />
        );

        const letters = [
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
        ];

        return (
            <AppModule loading={false} prepend={tableActionHeader}>
                <div className="myContainer">
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Title')}
                        itemDescription={
                            toolbox && toolbox.title != null && toolbox.title
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Author')}
                        itemDescription={
                            toolbox && toolbox.author != null && toolbox.author
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Sent')}
                        itemDescription={
                            currentAssign &&
                            currentAssign.deployed &&
                            moment(currentAssign.deployed).format(t('dateTime'))
                        }
                    />
                </div>
                <div style={{ marginBottom: '100px' }}>
                    {toolbox &&
                        toolbox.toolbox &&
                        toolbox.toolbox.questions.map((question, index) => {
                            return (
                                <div key={index}>
                                    {
                                        //check toegevoegd zodat oudere toolboxen ook nog werken
                                        <div className={classes.container2}>
                                            <div
                                                style={{
                                                    fontWeight: '500',
                                                    width: '900px',
                                                    fontSize: '15x',
                                                }}
                                            >
                                                {question.title !== null
                                                    ? question.title
                                                    : question.blocks &&
                                                      question.blocks[0] &&
                                                      question.blocks[0]
                                                          .textBlock.text &&
                                                      question.blocks[0]
                                                          .textBlock.text}
                                            </div>
                                            {currentAssign &&
                                                currentAssign.result &&
                                                currentAssign.result
                                                    .complete && (
                                                    <div
                                                        style={{
                                                            float: 'right',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {t('Chosen answer')}
                                                    </div>
                                                )}
                                        </div>
                                    }
                                    {question &&
                                        question.possibleAnswers &&
                                        question.possibleAnswers.map(
                                            (answer, index2) => (
                                                <div
                                                    key={index2}
                                                    style={{
                                                        fontWeight: '300',
                                                        fontSize: '14px',
                                                    }}
                                                    className={
                                                        classes.container
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            width: '950px',
                                                        }}
                                                    >
                                                        {letters[
                                                            index2 %
                                                                letters.length
                                                        ] +
                                                            '. ' +
                                                            answer.text +
                                                            ' '}{' '}
                                                        {question.correctAnswers.indexOf(
                                                            index2
                                                        ) > -1 && (
                                                            <b
                                                                style={{
                                                                    fontWeight:
                                                                        '500',
                                                                }}
                                                            >
                                                                {t(
                                                                    '(right answer)'
                                                                )}
                                                            </b>
                                                        )}
                                                    </div>
                                                    {currentAssign &&
                                                        currentAssign.result &&
                                                        currentAssign.result
                                                            .complete &&
                                                        currentAssign.result
                                                            .answers &&
                                                        currentAssign.result
                                                            .answers.length >
                                                            0 &&
                                                        currentAssign.result
                                                            .answers[0]
                                                            .answers !==
                                                            undefined && (
                                                            <React.Fragment>
                                                                {currentAssign
                                                                    .result
                                                                    .answers[
                                                                    index
                                                                ].answers &&
                                                                currentAssign.result.answers[
                                                                    index
                                                                ].answers.indexOf(
                                                                    index2
                                                                ) > -1 ? (
                                                                    <div
                                                                        className={
                                                                            'border-text'
                                                                        }
                                                                        style={{
                                                                            backgroundColor: getColorForPercentage(
                                                                                currentAssign &&
                                                                                    currentAssign.result &&
                                                                                    currentAssign
                                                                                        .result
                                                                                        .complete &&
                                                                                    currentAssign
                                                                                        .result
                                                                                        .answers[
                                                                                        index
                                                                                    ] &&
                                                                                    currentAssign.result.answers[
                                                                                        index
                                                                                    ].answers.indexOf(
                                                                                        index2
                                                                                    ) >
                                                                                        -1 &&
                                                                                    question.correctAnswers.indexOf(
                                                                                        index2
                                                                                    ) >
                                                                                        -1
                                                                                    ? 1
                                                                                    : 0
                                                                            ),
                                                                            borderColor: getColorForPercentage(
                                                                                currentAssign &&
                                                                                    currentAssign.result &&
                                                                                    currentAssign
                                                                                        .result
                                                                                        .complete &&
                                                                                    currentAssign
                                                                                        .result
                                                                                        .answers[
                                                                                        index
                                                                                    ]
                                                                                        .answers &&
                                                                                    currentAssign.result.answers[
                                                                                        index
                                                                                    ].answers.indexOf(
                                                                                        index2
                                                                                    ) >
                                                                                        -1 &&
                                                                                    question.correctAnswers.indexOf(
                                                                                        index2
                                                                                    ) >
                                                                                        -1
                                                                                    ? 1
                                                                                    : 0
                                                                            ),
                                                                            width:
                                                                                '70px',
                                                                            height:
                                                                                '23px',
                                                                            display:
                                                                                'inline-block',
                                                                            float:
                                                                                'right',
                                                                            marginTop:
                                                                                '-20px',
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'Chosen'
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    question.correctAnswers.indexOf(
                                                                        index2
                                                                    ) > -1 &&
                                                                    question.type ===
                                                                        'multiResponse' &&
                                                                    currentAssign &&
                                                                    currentAssign.result &&
                                                                    currentAssign
                                                                        .result
                                                                        .complete && (
                                                                        <div
                                                                            className={
                                                                                'border-text'
                                                                            }
                                                                            style={{
                                                                                backgroundColor: getColorForPercentage(
                                                                                    0
                                                                                ),
                                                                                borderColor: getColorForPercentage(
                                                                                    0
                                                                                ),
                                                                                width:
                                                                                    '90px',
                                                                                height:
                                                                                    '23px',
                                                                                display:
                                                                                    'inline-block',
                                                                                float:
                                                                                    'right',
                                                                                marginTop:
                                                                                    '-20px',
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                'Not chosen'
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                </div>
                                            )
                                        )}
                                </div>
                            );
                        })}
                </div>
            </AppModule>
        );
    }
}

var percentColors = percentColors1;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ToolboxResultsViewStats);
