import React from 'react'; // eslint-disable-line no-unused-vars
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    open: boolean,
    handleClose: () => mixed,
    onUpgrade: () => mixed,
    subscription: mixed,
    subscriptionUpgrade: mixed,
    subscriptionUpgraded: boolean,
    fromAboPage: boolean,
};

type State = {};

class UpgradeDialog extends React.PureComponent<Props, State> {
    props: Props;
    state: State = {};

    render() {
        const { t } = this.props;
        const subscription = this.props.subscription;
        const subscriptionUpgrade = this.props.subscriptionUpgrade;
        const subscriptionUpgraded = this.props.subscriptionUpgraded;
        const fromAboPage = this.props.fromAboPage;

        if (!subscription || !subscriptionUpgrade) {
            return <div />;
        }

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
            >
                <DialogTitle>
                    {subscriptionUpgraded
                        ? t('Subscription upgraded')
                        : t('Upgrade subscription')}
                </DialogTitle>
                {!subscriptionUpgraded && (
                    <DialogContent>
                        {subscriptionUpgrade.maxEmployees >= 25 ? (
                            fromAboPage ? (
                                <DialogContentText>
                                    {t('Upgrade subscription to')}{' '}
                                    {subscriptionUpgrade.aantalMedewerkersDescription.replace(
                                        'Tot',
                                        t('maximum')
                                    )}
                                    ? {t('Yearly costs are')} &euro;
                                    {subscriptionUpgrade.prijs},{' '}
                                    {t('added to current subscription')}.
                                </DialogContentText>
                            ) : (
                                <DialogContentText>
                                    {t('Reached maximum users')}.{' '}
                                    {t('Want to upgrade subscription to')}{' '}
                                    {subscriptionUpgrade.aantalMedewerkersDescription.replace(
                                        'Tot',
                                        t('maximum')
                                    )}
                                    ? {t('Yearly costs are')}: &euro;
                                    {subscriptionUpgrade.prijs},
                                    {t('added to current subscription')}.
                                    <br/>
                                    <br/>
                                    {t('Upgrade via')}.

                                </DialogContentText>
                            )
                        ) : subscriptionUpgrade.maxEmployees === -1 ? (
                            <DialogContentText>
                                {t('limit upgrade dialog')}
                            </DialogContentText>
                        ) : (
                            <DialogContentText>
                                {t('no limits upgrade dialog')}
                            </DialogContentText>
                        )}
                    </DialogContent>
                )}
                {subscriptionUpgraded && (
                    <DialogContent>
                        <DialogContentText>
                            {t('New subscription in main menu')}
                            <br />
                            {t('You will receive an confirmation email')}
                            <br />
                            {t('You can start right away')}
                        </DialogContentText>
                    </DialogContent>
                )}
                {!subscriptionUpgraded && (
                    <DialogActions>
                        {subscriptionUpgrade.maxEmployees > 25 &&
                        subscriptionUpgrade.maxEmployees <= 250 ? (
                            <div>
                                <Button
                                     
                                    onClick={this.props.handleClose}
                                >
                                    {t('Close')}
                                </Button>
                            </div>
                        ) : (
                            <Button
                                 
                                onClick={this.props.handleClose}
                            >
                                {t('Close')}
                            </Button>
                        )}
                    </DialogActions>
                )}

                {subscriptionUpgraded && (
                    <DialogActions>
                        <Button   onClick={this.props.handleClose}>
                            {t('Close')}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    }
}
export default compose(withTranslation())(UpgradeDialog);
