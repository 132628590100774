// @flow
import React from 'react';
import {
    Button,
    TextField,
    DialogContent,
    DialogActions,
    DialogContentText,
    Select,
    MenuItem,
} from '@mui/material';
import type { TSubscription } from '../../reducers/subscriptionReducer';
import type { TEmployee } from '../../reducers/employeesReducer';
import { fetchEmployees } from '../../actions/employeeActions';
import { fetchSubscriptionCategories } from '../../actions/subscriptionActions';
import { showSnackbar } from '../../actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './AbonneeEditCard.module.scss'

type Props = {
    abonnee?: TSubscription,
    onEdit: (abonnee?: TSubscription) => mixed,
    onSave: (user: TEmployee, onSucces: () => mixed) => mixed,
    goBack: () => mixed,
    clearDiff: () => mixed,
    employeeDropDownList: Array<any>,
    employees: Array<any>,
    categories: Array<any>,
    fetchCategories: (id: Number) => mixed,
};

type State = {
    saving: boolean,
    abonneeNew: TEmployee,
    extraOption: boolean,
    state: 'ACTIVE',
};

const mapStateToProps = (store) => {
    const employees = store.entities.employees;
    const categories = store.subscription.categories;

    return {
        employees: employees.allIds.map((id) => employees.byId[id]),
        categories: categories,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchEmployees: () => {
            dispatch(fetchEmployees());
        },
        fetchCategories: (id: Number) => {
            dispatch(fetchSubscriptionCategories(id));
        },
        goToDetails: (newOb) => {
            dispatch(
                push(
                    '/abonnee/details/' + newOb.subscription.id + '#abonnement'
                )
            );
        },
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
    };
};

class AbonneeEditCard extends React.Component<Props, State> {
    props: Props;
    state: State = {
        extraOption: false,
    };
    constructor(props) {
        super(props);
        if (props && props.abonnee) {

            if (props.abonnee.maxEmployees > 250) {
                this.state = {extraOption: true };
            } else {
                this.state = {extraOption: false };
            }
            this.props.onEdit(
                Object.assign({}, props.abonnee, { comments: '' })
            );

        }
    }

    componentDidMount() {
        if (this.props && this.props.abonnee) {
            this.props.fetchEmployees();
            this.props.fetchCategories(this.props.abonnee.id);
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.employees && this.props.abonnee!== prevProps.abonnee && this.props.abonnee !== undefined) {
            this.setState({
                abonneeNew: this.props.employees ? this.props.employees.filter(
                        (employee) => this.props.abonnee.idSubscriptionHolder === employee.id)[0] : undefined,
            });
        }
    }

    handleSubscriptionHolderChange = (event: any) => {
        console.log('XXX handleSubscriptionHolderChange: ', event);
        if (event) {
            const newSubscriptionHolder = this.props.employees.filter(
                (employee) => event.target.value.id === employee.id
            )[0];
            console.log('XXX changing subscriptionHolder to : ', newSubscriptionHolder.fullname);
            this.setState({ abonneeNew: newSubscriptionHolder });
        }
    };

    handleMaxEmployeesChange = (event: any) => {
        this.props.onEdit(
            Object.assign({}, this.props.abonnee, {
                maxEmployees: event.target.value,
            })
        );

        //if the option with more than 250 employees is selected => we can add a personalized offer with a nr of max employees and a price
        if (event.target.value === '-1') {
            this.setState({
                extraOption: true,
            });
        } else {
            this.setState({
                extraOption: false,
            });
        }
    };

    handleStatusChange = (event: any) => {
        this.setState({ state: event.target.value });
        if (event.target.value === 'Proef') {
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    state: 'CONFIRMED',
                    trial: true,
                })
            );
        } else if (event.target.value === 'Inactief') {
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    state: 'DISABLED',
                    trial: false,
                })
            );
        } else {
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    state: 'CONFIRMED',
                    trial: false,
                })
            );
        }
    };

    handleChangePaymentChange = (event: any) => {
        this.setState({ state: event.target.value });
        if (event.target.value === true) {
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, { changePayment: true })
            );
        } else {
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, { changePayment: false })
            );
        }
    };

    handleTypeChange = (event: any) => {
        this.setState({ type: event.target.value });
        if (event.target.value === 'Standaard') {
            //standard
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    credits_setup: false,
                    vv_functions: true,
                    cumela: false,
                    caoLeo: false,
                })
            );
        } else if (event.target.value === 'Standaard (+ Sneldelen)') {
            //standard+sneldelen
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    credits_setup: true,
                    vv_functions: true,
                    cumela: false,
                    caoLeo: false,
                })
            );
        } else if (event.target.value === 'Cumela') {
            //cumela
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    credits_setup: false,
                    vv_functions: true,
                    cumela: true,
                    caoLeo: false,
                })
            );
        } else if (event.target.value === 'Cumela (+ Sneldelen)') {
            //cumela+sneldelen
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    credits_setup: true,
                    vv_functions: true,
                    cumela: true,
                    caoLeo: false,
                })
            );
        } else if (event.target.value === 'CAO/LEO') {
            //caoleo
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    credits_setup: false,
                    vv_functions: true,
                    cumela: false,
                    caoLeo: true,
                })
            );
        } else if (event.target.value === 'CAO/LEO (+ Sneldelen)') {
            //caoleo+sneldelen
            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    credits_setup: true,
                    vv_functions: true,
                    cumela: false,
                    caoLeo: true,
                })
            );
        } else if (event.target.value === 'Sneldelen only') {
            //sneldelen only

            this.props.onEdit(
                Object.assign({}, this.props.abonnee, {
                    credits_setup: true,
                    vv_functions: false,
                })
            );
        }
    };

    handleExtraMaxEmployeesChange = (event: any) => {
        this.props.onEdit(
            Object.assign({}, this.props.abonnee, {
                maxEmployees: event.target.value,
            })
        );
    };

    handleExtraPriceChange = (event: any) => {
        this.props.onEdit(
            Object.assign({}, this.props.abonnee, { price: event.target.value })
        );
    };

    handleCommentsChange = (event: any) => {
        this.props.onEdit(
            Object.assign({}, this.props.abonnee, {
                comments: event.target.value,
            })
        );
    };

    handleCreditsChange = (event: any) => {
        this.props.onEdit(
            Object.assign({}, this.props.abonnee, {
                credits: event.target.value,
            })
        );
    };

    handleExpiryDateChange = (event: any) => {
        try {
            var now = new Date();
            var submittedDate = new Date(
                event.target.value.replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    '$2/$1/$3'
                )
            );
            if (submittedDate <= now) {
                this.setState({
                    saving: true,
                });
                this.setState({ dateIsInPast: true });
            } else {
                this.setState({
                    saving: false,
                });
                this.setState({ dateIsInPast: false });
            }
        } catch (e) {}
        this.props.onEdit(
            Object.assign({}, this.props.abonnee, {
                expiryDate: event.target.value,
            })
        );
    };

    handleCreatedOnDateChange = (event: any) => {
        this.props.onEdit(
            Object.assign({}, this.props.abonnee, {
                dateCreatedOn: event.target.value,
            })
        );
    };

    cancelEdit = () => {
        this.props.onEdit(undefined);
        this.props.goBack();
    };

    saveAbonnee = () => {
        this.setState({
            saving: true,
        });
        //let errors: Map<string, string> = new Map();

        if (!this.props.abonnee) {
            //TODO error?
            return;
        }

        const newOb = {};
        if (this.props.abonnee.expiryDate != null) {
            //Transfer JS datepicker to Java UTC format
            let date = new Date(
                this.props.abonnee.expiryDate.replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    '$2/$1/$3'
                )
            );
            date =
                date.getUTCFullYear() +
                '-' +
                date.getUTCMonth() +
                '-' +
                date.getUTCDate() +
                'T00:00:00Z';
            this.props.abonnee.expiryDate = date;
        }

        if (
            this.props.abonnee.dateCreatedOn != null &&
            this.props.abonnee.dateCreatedOn !== ''
        ) {
            //Transfer JS datepicker to Java UTC format
            console.log('createdOn date: ' + this.props.abonnee.dateCreatedOn);
            let date = new Date(
                this.props.abonnee.dateCreatedOn.replace(
                    /(\d{2})-(\d{2})-(\d{4})/,
                    '$2/$1/$3'
                )
            );
            date =
                date.getUTCFullYear() +
                '-' +
                (parseInt(date.getUTCMonth(),10) + 1) +
                '-' +
                date.getUTCDate() +
                'T00:00:00Z';
            this.props.abonnee.dateCreatedOn = date;
        } else {
            this.props.showSnackbar('Startdatum ongeldig');
            this.setState({
                saving: false,
            });
            return;
        }
        newOb.id = this.props.abonnee.idSubscriptionHolder;
        newOb.subscription = this.props.abonnee;
        newOb.user = this.state.abonneeNew;

        this.setState(Object.assign({}, this.state));
        this.props.onSave(
            Object.assign({}, newOb),
            (this.onSucces = () => {
                this.props.onEdit(undefined);
                this.setState({
                    saving: false,
                });
            })
        );

        this.props.onEdit(undefined);
        this.props.goToDetails(newOb);
    };

    abonneeStateToText = (state, trial) => {
        const { t } = this.props;
        let text = '';

        switch (state) {
            case 'REQUESTED':
                text = t('Inactive');
                break;
            case 'CONFIRMED':
                text = t('Active');
                break;
            case 'DISABLED':
                text = t('Inactive');
                break;
            default:
                text = 'aaaaa';
        }
        //check if the subscription is just a trial
        if (text === 'Actief' && trial === true) {
            text = 'Proef';
        }

        return text;
    };

    render() {
        //Als we geen employee hebben niets renderen
        if (!this.props.abonnee) {
            return <div></div>;
        }

        const abonnee = this.props.abonnee;
        const employees = this.props.employees;
        const categories = this.props.categories;

        let startDate = undefined;
        if (abonnee && abonnee.dateCreatedOn) {
            startDate = abonnee.dateCreatedOn.split('T')[0];
        }
        const { t } = this.props;

        console.log('abonnee', abonnee);
        console.log('new', this.state.abonneeNew);
        console.log('categories', categories);

        if (!this.state.abonneeNew || !categories) return <div></div>;

        return (
            <div>
                <DialogContent>
                    <DialogContentText
                        className={classes.text}
                        style={{ marginTop: '-20px', color: 'black' }}
                    >
                        {t('Enter the changes to your subscription below')}
                    </DialogContentText>
                    <DialogContentText
                        className={classes.text}
                        style={{ marginTop: '20px' }}
                    >
                        {t('Contact person')}
                    </DialogContentText>
                    <Select
                        id="subscriptionholder"
                        value={this.state.abonneeNew}
                        onChange={this.handleSubscriptionHolderChange}
                    >
                        {employees
                            .filter(
                                (empl) =>
                                    abonnee.idSubscriptionHolder ===
                                        empl.idSubscriptionHolder &&
                                    empl.roles.includes('COORDINATOR')
                            )
                            .map((employee) => (
                                <MenuItem key={employee.id} value={employee}>
                                    {employee.fullname}
                                </MenuItem>
                            ))}
                    </Select>

                    <DialogContentText
                        className={classes.text}
                        style={{ marginTop: '20px' }}
                    >
                        {t('Subscription type')}
                    </DialogContentText>

                    <Select
                        id="type"
                        className={classes.maxEmployees}
                        placeholder="Select type"
                        label="type"
                        name="type"
                        value={
                            abonnee.credits_setup && !abonnee.vv_functions
                                ? 'Sneldelen only'
                                : abonnee.cumela === true
                                ? abonnee.credits_setup
                                    ? 'Cumela (+ Sneldelen)'
                                    : 'Cumela'
                                : abonnee.caoLeo === true
                                ? abonnee.credits_setup
                                    ? 'CAO/LEO (+ Sneldelen)'
                                    : 'CAO/LEO'
                                : abonnee.credits_setup
                                ? 'Standaard (+ Sneldelen)'
                                : 'Standaard'
                        }
                        onChange={this.handleTypeChange}
                    >
                        <MenuItem value={t('Standard')}>
                            {t('Standard')}
                        </MenuItem>
                        <MenuItem value={t('Standard (+ Express sharing)')}>
                            {t('Standard (+ Express sharing)')}
                        </MenuItem>
                        <MenuItem value={t('Cumela')}>{t('Cumela')}</MenuItem>
                        <MenuItem value={t('Cumela (+ Express sharing)')}>
                            {t('Cumela (+ Express sharing)')}
                        </MenuItem>
                        <MenuItem value={t('CAO/LEO')}>{t('CAO/LEO')}</MenuItem>
                        <MenuItem value={t('CAO/LEO (+ Express sharing)')}>
                            {t('CAO/LEO (+ Express sharing)')}
                        </MenuItem>
                        <MenuItem value={t('Sneldelen only')}>
                            {t('Sneldelen only')}
                        </MenuItem>
                    </Select>

                    <DialogContentText
                        className={classes.text}
                        style={{ marginTop: '20px' }}
                    >
                        {t('Max. number of employees')}
                    </DialogContentText>

                    <Select
                        id="maxEmployees"
                        className={classes.maxEmployees}
                        placeholder={t('Select max Employees')}
                        label={t('Max Employees/Costs')}
                        name={t('Max Employees/Costs')}
                        value={abonnee.maxEmployees.toString()}
                        onChange={this.handleMaxEmployeesChange}
                    >
                        {categories.map((c) => (
                            <MenuItem
                                key={c.maxEmployees}
                                value={c.maxEmployees}
                            >
                                {c.aantalMedewerkersDescription}
                            </MenuItem>
                        ))}
                    </Select>

                    <TextField
                        id="extraMaxEmployees"
                        className={
                            this.state.extraOption
                                ? classes.extraMaxEmployees
                                : classes.hidden
                        }
                        label={t('Max employees')}
                        value={abonnee.maxEmployees}
                        onChange={this.handleExtraMaxEmployeesChange}
                    />

                    <TextField
                        id="extraPrice"
                        className={
                            this.state.extraOption
                                ? classes.extraPrice
                                : classes.hidden
                        }
                        label={t('Costs')}
                        value={abonnee.price}
                        onChange={this.handleExtraPriceChange}
                    />

                    <DialogContentText
                        className={classes.text}
                        style={{ marginTop: '20px' }}
                    >
                        {t('State')}
                    </DialogContentText>

                    <Select
                        id="status"
                        className={classes.maxEmployees}
                        placeholder={t('Select status')}
                        label="Status"
                        name="Status"
                        value={this.abonneeStateToText(
                            abonnee.state,
                            abonnee.trial
                        )}
                        onChange={this.handleStatusChange}
                    >
                        <MenuItem value={'Actief'}>Actief</MenuItem>
                        <MenuItem value={'Inactief'}>Inactief</MenuItem>
                        <MenuItem value={'Proef'}>Proef</MenuItem>
                    </Select>
                    <DialogContentText
                        className={classes.text}
                        style={{ marginTop: '20px' }}
                    >
                        {t('Can adjust payment details')}
                    </DialogContentText>

                    <Select
                        id="changePayment"
                        className={classes.maxEmployees}
                        placeholder={t('Can adjust user payment information')}
                        label={t('Can adjust user payment information')}
                        name="changePayment"
                        value={abonnee.changePayment}
                        onChange={this.handleChangePaymentChange}
                    >
                        <MenuItem value={true}>{t('YES')}</MenuItem>
                        <MenuItem value={false}>{t('NO')}</MenuItem>
                    </Select>
                    {this.state.type === 'Sneldelen only' && abonnee.stripeId && (
                        <DialogContentText
                            className={classes.text}
                            style={{ marginTop: '20px' }}
                        >
                            {t('This customer uses direct debit,')}
                            <br></br>
                            {t('stop direct debit in Stripe.')}
                        </DialogContentText>
                    )}
                    {this.state.state === 'Inactief' &&
                        abonnee.stripeId &&
                        abonnee.changePayment && (
                            <DialogContentText
                                className={classes.text}
                                style={{ marginTop: '20px' }}
                            >
                                {t('Customer uses direct debit!')} <br></br>
                                {t(
                                    'The subscription will be terminated after the'
                                )}
                                <br></br>
                                {t('paid period has ended!')}
                                <br></br>
                                {t('Or enter another date:')}
                            </DialogContentText>
                        )}
                    {this.state.state === 'Inactief' &&
                        abonnee.stripeId &&
                        !abonnee.changePayment && (
                            <DialogContentText
                                className={classes.text}
                                style={{ marginTop: '20px' }}
                            >
                                {t('Set up direct debit for this customer')}
                                {t('manually stop in Stripe!')} <br></br>
                                {t(
                                    'Subscription ends immediately or enter an expiry date:'
                                )}
                            </DialogContentText>
                        )}
                    {this.state.state === 'Inactief' && !abonnee.stripeId && (
                        <DialogContentText
                            className={classes.text}
                            style={{ marginTop: '20px' }}
                        >
                            {t('Customer makes')} <b>{'not'}</b>{' '}
                            {t('use of direct debit')} <br></br>
                            {t(
                                'Subscription ends immediately or enter an expiry date:'
                            )}
                        </DialogContentText>
                    )}
                    {this.state.state === 'Inactief' && (
                        <TextField
                            error={this.state.dateIsInPast}
                            
                            type="date"
                            id="expiryDate"
                            format="MM-dd-yyyy"
                            value={abonnee.expiryDate}
                            onChange={this.handleExpiryDateChange}
                        />
                    )}
                    {abonnee.trial && (
                        <DialogContentText
                            className={classes.text}
                            style={{ marginTop: '10px' }}
                        >
                            {t('Subscription start date')}
                        </DialogContentText>
                    )}
                    {abonnee.trial && (
                        <TextField
                            error={this.state.dateIsInPast}
                            
                            type="date"
                            style={{ marginTop: '5px', marginBottom: '0px' }}
                            id="dateCreatedOn"
                            defaultValue={startDate}
                            onChange={this.handleCreatedOnDateChange}
                        />
                    )}

                    {abonnee.credits_setup && (
                        <div>
                            <DialogContentText
                                className={classes.text}
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '0px',
                                }}
                            >
                                {t('Credit amount')}
                            </DialogContentText>

                            <TextField
                                error={this.state.dateIsInPast}
                                
                                className={classes.maxEmployees}
                                style={{
                                    marginTop: '5px',
                                    marginBottom: '0px',
                                }}
                                type="number"
                                id="credits"
                                defaultValue={abonnee.credits}
                                onChange={this.handleCreditsChange}
                            />
                        </div>
                    )}

                    <DialogContentText
                        className={classes.text}
                        style={{ marginTop: '10px' }}
                    >
                        {t('Comments')}
                    </DialogContentText>
                    <textarea
                        id="comments"
                        rows="2"
                        cols="50"
                        className={classes.comments}
                        value={abonnee.comments}
                        onChange={this.handleCommentsChange}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                         
                        className="button cancel"
                        onClick={this.cancelEdit}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                         
                        className="button add"
                        onClick={this.saveAbonnee}
                        disabled={this.state.saving}
                    >
                        {abonnee.id ? t('Save') : t('Add')}
                    </Button>
                </DialogActions>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(AbonneeEditCard);
