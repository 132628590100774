// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { List } from 'immutable';
import type { TProject } from '../../reducers/projectsReducer';
import { bindActionCreators } from 'redux';

import ProjectEdit from './ProjectEdit';
import ProjectOverview from './ProjectOverview';
import { fetchProjects, setCrumbPath, editProject } from '../../actions';

type Props = {
    match: { params: { id: string } },
    projects: List<TProject>,
    project?: TProject,
    editProject: (project: TProject) => mixed,
    setEditProject: (project: TProject) => mixed,
    projectsById: { [string]: TProject }
};

type State = {
    detailDialogOpen: boolean
};

const mapStateToProps = (store, props) => {
    const loading = store.entities.projects.isFetching;
    const projects = store.entities.projects;
    const project = store.drafts.projects.editproject;

    return {
        loading: loading,
        projectsById: projects.byId,
        projects: projects.allIds.map(id => projects.byId[id]),
        project: project
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
        editProject: id => {
            dispatch(push('/projecten/' + id));
        },
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Projecten/werkgebieden' }));
        },
        setEditProject: bindActionCreators(editProject, dispatch)
    };
};

class Project extends React.Component<Props, State> {
    props: Props;
    state: State = {
        detailDialogOpen: false
    };

    handleRequestClose = event => {
        this.setState({ detailDialogOpen: false });
    };

    componentDidMount() {
        this.props.fetchProjects();
        this.props.setCrumbPath();

        this.processProps(this.props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.processProps(this.props)
    }

    processProps = (props: Props) => {
        const paramId = props.match.params.id ? props.match.params.id : undefined;

        if (!props.project || props.project.id !== paramId) {
            if (props.projectsById && paramId) {
                props.setEditProject(props.projectsById[paramId]);
            } else {
                //Remove if project was set
                props.setEditProject(undefined);
            }
        }
    };

    onCancel = (project) => {
        //this.props.setEditProject(null);
    };

    render() {
        const showDetailDialog = this.props.match.params.id !== undefined;
        const { projects, project } = this.props;
        return (
            <div className="Project">
                <ProjectOverview projects={projects} startEditProject={this.props.editProject} />
                <ProjectEdit open={showDetailDialog} editproject={project} onEdit={this.onCancel}/>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Project);
