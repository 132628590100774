// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import _ from 'lodash';
import { setCrumbPath } from '../../actions';
import { fetchAbonnees, fetchHistory } from '../../actions/subscriptionActions';
import AbonneeInformation from '../Subscriptions/AbonneeInformation';
import i18n from 'i18next';

type Props = {
    match: any,
    abonnee?: any,
    fetchAbonnees: () => mixed,
    fetchHistory: (id: Number) => mixed,
    setCrumbPath: (abonneeCompanyName: string) => void,
    history: Array<any>,
};


const mapStateToProps = (store, props) => {
    const abonnees = store.subscription.abonnees;
    const abonneeId = props.match.params.abonneeId;

    const history = store.subscription.history;

    let abonneesById = [];
    if (abonnees) {
        abonnees.forEach((abonee) => {
            abonneesById[abonee.id] = abonee;
        });
    }

    let abonnee = abonneesById[abonneeId];

    return {
        abonnee: abonnee,
        abonnees: abonnees,
        abonneesById: abonneesById,
        history: history,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAbonnees: () => {
            dispatch(fetchAbonnees());
        },
        setCrumbPath: (abonneeCompanyName: string) => {
            dispatch(
                setCrumbPath({
                    title: i18n.t('Subscribers'),
                    crumbs: [{ name: abonneeCompanyName, link: '/abonnees' }],
                })
            );
        },
        fetchHistory: (id: Number) => {
            dispatch(fetchHistory(id));
        },
    };
};

class AbonneeDetailView extends React.Component<Props, State> {
    props: Props;

    state: State = {
        searchtext: '',
        actions: [],
        fetched: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.fetched === false) {
            this.props.fetchAbonnees();
            this.setState({ fetched: true });
        }
        if (this.props.abonnee &&
            _.get(prevProps, 'abonnee.id', null) !==
            _.get(this.props, 'abonnee.id', null)
        ) {
            this.props.setCrumbPath(this.props.abonnee.companyName);
        }
    }

    render() {
        const { abonnee } = this.props;
        const history = this.props.history;

        return (
            <div>
                <AbonneeInformation
                    abonnee={abonnee}
                    history={history}
                    cumela={false}
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AbonneeDetailView);
